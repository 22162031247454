
export default {
    async validateCurriculumCourseUpdate (refs, item, formData, yearList) {
        const yearItems = yearList
        const response = {
            hasEffect: false,
            warningEditForm: null,
            effectON: [],
            formData: null
        }

        const res = await refs.axios.get(`v2/curriculum/course/validate?departmentCourseId=${item.id}`)
        if (res && res.data && res.data.status === 'ok') {
            response.hasEffect = res.data.data.hasEffect
            response.effectON = res.data.data.curriculumList

            const affectedCurriculum = res.data.data.curriculumList.map((ic) => { return ic.curriculumName })
            let affectedCurriculumString = affectedCurriculum.toString()

            if (affectedCurriculum.length > 1) {
                const lastAffectedCurriculum = affectedCurriculum.pop()
                affectedCurriculumString = affectedCurriculum.toString().concat(' ', refs.$vuetify.lang.t('$vuetify.EditWarningModal.conjunction'), ' ', lastAffectedCurriculum)
            }

            if (response.hasEffect === true) {
                response.warningEditForm = {
                    header: `${refs.$vuetify.lang.t('$vuetify.EditWarningModal.modalHeader')} "${item.code}" ?`,
                    content: `${refs.$vuetify.lang.t('$vuetify.EditWarningModal.modalContent')}`,
                    curriculums: `${affectedCurriculumString}`,
                    lastContent: ` ${refs.$vuetify.lang.t('$vuetify.EditWarningModal.lastModalContent')}`
                }
            }

            formData = Object.assign(formData, item)
            formData.year = yearItems.find(year => year.text === formData.year)
            delete formData.createDate
            response.formData = formData

            return response
        } else {
            return null
        }
    },

    async validateCurriculumCourseDelete (refs, item) {
        let hasEffect = false
        const response = {
            deleteForm: null,
            effectON: [],
            formData: null
        }

        const res = await refs.axios.get(`v2/curriculum/course/validate?departmentCourseId=${item.id}`)
        if (res && res.data && res.data.status === 'ok') {
            hasEffect = res.data.data.hasEffect
            response.effectON = res.data.data.curriculumList
            const affectedCurriculum = res.data.data.curriculumList.map((ic) => { return ic.curriculumName })
            let affectedCurriculumString = affectedCurriculum.toString()

            if (affectedCurriculum.length > 1) {
                const lastAffectedCurriculum = affectedCurriculum.pop()
                affectedCurriculumString = affectedCurriculum.toString().concat(' ', refs.$vuetify.lang.t('$vuetify.EditWarningModal.conjunction'), ' ', lastAffectedCurriculum)
            }

            if (hasEffect === true) {
                response.deleteForm = {
                    header: refs.$vuetify.lang.t('$vuetify.deleteModal.headerText'),
                    content: `การลบจะส่งผลต่อหลักสูตรของปีการศึกษา 
                            "${affectedCurriculumString}" ที่ยังมีสถานะฉบับร่าง`,
                    path: `v2/department/course/${item.id}`
                }
            } else {
                response.deleteForm = {
                    header: refs.$vuetify.lang.t('$vuetify.deleteModal.headerText'),
                    content: `" ${item.code} ${item.name} "`,
                    path: `v2/department/course/${item.id}`

                }
            }
            return response
        } else {
            return null
        }
    },

    async validateDepartmentUpdate (refs, item) {
        const response = {
            hasEffect: false,
            warningEditForm: null,
            effectON: []
        }

        const res = await refs.axios.get(`v2/curriculum/course/validate?departmentId=${item.id}`)
        if (res && res.data && res.data.status === 'ok') {
            response.hasEffect = res.data.data.hasEffect
            response.effectON = res.data.data.curriculumList
            const affectedCurriculum = res.data.data.curriculumList.map(function (ic) { return ic.curriculumName })
            let affectedCurriculumString = affectedCurriculum.toString()
            if (affectedCurriculum.length > 1) {
                const lastAffectedCurriculum = affectedCurriculum.pop()
                affectedCurriculumString = affectedCurriculum.toString().concat(' ', refs.$vuetify.lang.t('$vuetify.EditWarningModal.conjunction'), ' ', lastAffectedCurriculum)
            }
            if (response.hasEffect === true) {
                response.warningEditForm = {
                    header: `${refs.$vuetify.lang.t('$vuetify.EditWarningModal.modalHeader')} "${item.department}" ?`,
                    content: `${refs.$vuetify.lang.t('$vuetify.EditWarningModal.modalContent')}`,
                    curriculums: `${affectedCurriculumString}`,
                    lastContent: ` ${refs.$vuetify.lang.t('$vuetify.EditWarningModal.lastModalContent')}`
                }
            }
            return response
        } else {
            return null
        }
    },

    async validateDepartmentDelete (refs, item) {
        let hasEffect = false
        const response = {
            deleteForm: null,
            effectON: []
        }

        const res = await refs.axios.get(`v2/curriculum/course/validate?departmentId=${item.id}`)
        if (res && res.data && res.data.status === 'ok') {
            hasEffect = res.data.data.hasEffect
            response.effectON = res.data.data.curriculumList
            const affectedCurriculum = res.data.data.curriculumList.map(function (ic) { return ic.curriculumName })
            let affectedCurriculumString = affectedCurriculum.toString()
            if (affectedCurriculum.length > 1) {
                const lastAffectedCurriculum = affectedCurriculum.pop()
                affectedCurriculumString = affectedCurriculum.toString().concat(' ', refs.$vuetify.lang.t('$vuetify.EditWarningModal.conjunction'), ' ', lastAffectedCurriculum)
            }
            if (hasEffect === true) {
                response.deleteForm = {
                    header: refs.$vuetify.lang.t('$vuetify.deleteModal.headerText'),
                    content: `การลบจะส่งผลต่อหลักสูตรของปีการศึกษา 
                            "${affectedCurriculumString}" ที่ยังมีสถานะฉบับร่าง`,
                    path: `v2/department/delete/${item.id}`
                }
            } else {
                response.deleteForm = {
                    header: refs.$vuetify.lang.t('$vuetify.deleteModal.headerText'),
                    content: `${refs.$vuetify.lang.t('$vuetify.deleteModal.firstContentText')} 
                            "${item.department}" ${refs.$vuetify.lang.t('$vuetify.deleteModal.lastContentText')}`,
                    path: `v2/department/delete/${item.id}`
                }
            }
            return response
        } else {
            return null
        }
    }
}
