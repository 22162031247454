<template>
    <div class="cardErrorLineLiff">
        <v-card class="card-line d-flex flex-column">
            <div class="modal-position icon-position">
                <slot name="icon"></slot>
            </div>
            <v-card-title class="modal-position py-0">
                <slot name="modal-header"></slot>
            </v-card-title>
            <v-card-text
                class="modal-content px-6 d-flex flex-column flex-grow-1"
            >
                <slot name="modal-content" class="style-content">
                    <span>asdasd</span>
                </slot>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "CardErrorLineLiff",
};
</script>

<style lang="scss" scoped>
.cardErrorLineLiff {
    background-color: #f5f5f5;
    height: 100vh;
    display: flex;
    align-items: center;
}
.modal-position {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
}
.card-line {
    margin-inline: 27px;
    min-height: 370px;
    padding-block: 70px;
    width: 100vw;
    border-radius: 15px;
}
::v-deep.modal-content {
    color: $spanish-grey !important;
}
</style>
