export default {
    timetableTemplate: 'เวลาเรียนค่าตั้งต้น',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    closed: 'ปิด',
    opened: 'เปิด',
    saveSuccess: 'บันทึกสำเร็จ',
    remarkContent: {
        header: 'คำแนะนำการตั้งค่าตารางเรียน',
        body1: '1. จำนวนคาบเรียน',
        body2: 'รวมพักกลางวัน',
        body3: '2. ตั้งค่าหัวตารางเผื่อกรณีเพิ่มหมายเหตุ หรือวิชาที่ไม่ได้อยู่ในเมนูจัดการหลักสูตร เช่น โฮมรูม'
    }
}
