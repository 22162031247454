export default {
    header: 'หมายเหตุ',
    label: {
        day: 'วัน',
        startTime: 'เริ่มเวลา',
        endTime: 'สิ้นสุดเวลา',
        note: 'หมายเหตุ'
    },
    placeholder: {
        day: '- เลือกวัน -',
        startTime: '- เลือกเวลา -',
        endTime: '- เลือกเวลา -',
        note: 'เช่น ประชุมกลุ่มสาระ'
    },
    errorMessage: {
        required: 'จำเป็นต้องกรอก',
        dayInvalid: 'กรุณาเลือกวัน',
        timeInvalid: 'ไม่สามารถเลือกเวลานี้ได้'
    },
    notify: {
        title: 'เพิ่มหมายเหตุ'
    }
}