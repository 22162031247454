<template>
    <notifications
        :duration="10000"
        :width="335"
        position="bottom right"
        closeOnClick>
        <template
            slot="body"
            slot-scope="{item, close}">
            <v-row
                no-gutters
                @click="close"
                :class="alertClass(item.type)"
                class="notification">
                <v-col
                    cols="2"
                    class="d-flex align-center justify-center">
                    <v-icon>{{ iconAlert(item.type) }}</v-icon>
                </v-col>
                <v-col
                    cols="10"
                    class="py-2 pr-2 d-flex align-center flex-wrap">
                    <div
                        v-if="item.title"
                        class="alert-title text-title">
                        {{ item.title }}
                    </div>
                    <div
                        no-gutters
                        class="alert-text text-content large">
                        {{ item.text }}
                    </div>
                </v-col>
            </v-row>
        </template>
    </notifications>
</template>

<script>
export default {
    name: 'MyNotification',

    data () {
        return {
            type: null
        }
    },

    computed: {
        textAlert(text) {
            return text
        },

        setWidth() {
            if (this.type === "success") {
                return 171
            } else {
                return 374
            }
        }
    },

    methods: {
        alertClass(type) {
            this.type = type;
            if (type === 'success') {
                return 'success-alert'
            } else if (type === 'error') {
                return 'error-alert'
            } else {
                return 'warning-alert'
            }
        },

        iconAlert(type) {
            if (type === 'success') {
                return 'check_circle'
            } else if (type === 'error') {
                return 'error'
            } else {
                return 'warning'
            }
        },

        textAlertClass(title) {
            if (title) {
                return 'alert-text'
            } else {
                return 'alert-text-no-title'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.notification {
    margin-top: 10px;
    border-radius: 5px;
    min-height: 64px;
    .v-icon {
        color: $white;
    }

}
.warning-alert {
    background-color: $vivid-gamboge !important;
}

.error-alert {
    background-color: $orange-red !important;
}

.success-alert {
    background-color: $caribbean-green !important;
}

.alert-text {
    color : $white !important;
    width: 100%;
}

.alert-title {
    color : $white !important;
    width: 100%;
}
</style>
