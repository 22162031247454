export default {
    addCourse: 'เพิ่มวิชา',
    selectCourse: '- เลือกกลุ่มสาระการเรียนรู้ -',
    selectDepartment: '- เลือกวิชา -',
    course: 'วิชา',
    header: 'วิชาเรียน',
    department: 'กลุ่มสาระการเรียนรู้',
    semester1: 'ภาคเรียนที่ 1',
    semester2: 'ภาคเรียนที่ 2',
    semester3: 'ภาคเรียนที่ 3',
    semester4: 'ภาคเรียนที่ 4',
    semesterSummer: 'ซัมเมอร์',
    courseDetail: 'ข้อมูลรายวิชา',
    courseId: 'รหัสวิชา',
    courseName: 'ชื่อวิชา',
    courseType: 'ประเภท',
    hrPerYear: 'ชม./ภาคเรียน',
    credit: 'หน่วยกิต',
    sessionPerWeek: 'คาบ / สัปดาห์',
    importedBy: 'นำเข้าโดย',
    starDetail: 'กรุณาระบุรายวิชาให้ครบถ้วนก่อนบันทึก เนื่องจากข้อมูลจะมีผลต่อการจัดตารางเรียน',
    totalCourse: 'จำนวนวิชาทั้งหมด',
    totalSessionWeek: 'จำนวนคาบ',
    totalBasic: 'รายวิชาพื้นฐาน',
    totalAdditional: 'รายวิชาเพิ่มเติม',
    totalActivity: 'กิจกรรม',
    totalTime: 'รวมเวลาเรียนทั้งสิ้น',
    saveDraft: 'บันทึกฉบับร่าง',
    save: 'บันทึก',
    add: 'เพิ่มรายวิชา',
    courseUnit: 'วิชา',
    timeUnitPerWeek: 'คาบ / สัปดาห์',
    timeUnitPrimary: 'ชม./ปี',
    timeUnit: 'ชม./ภาคเรียน',
    requiredCourse: 'Required Subject',
    selectiveCourse: 'Selective Subject',
    counsellingActivity: 'Counselling Activities',
    studentActivity: 'Student Activity',
    activityForSocial: 'Activity for Social and Public Interest'
}
