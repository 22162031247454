export default {
    title: {
        add: 'เพิ่มวิชา',
        edit: 'แก้ไขวิชา'
    },
    subTitle: 'กลุ่มสาระ',
    label: {
        gradingType: {
            title: 'การประเมินผลการเรียนรู้ในรายวิชา',
            choice1: 'ตัดสินเป็นเกรด',
            choice2: 'ตัดสินเป็นผ่าน / ไม่ผ่าน'
        },
        type: 'ประเภท',
        isShownTimeTable: 'ไม่แสดงบนตารางเรียน',
        isShownTimeTableNote: {
            text1: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์จะ',
            text2: 'ไม่แสดงบนตารางเรียน'
        },
        code: 'รหัสวิชา ',
        name: 'ชื่อวิชา (ไทย)',
        nameEn: 'ชื่อวิชา (อังกฤษ)',
        year: 'ระดับชั้น',
        semester: 'ภาคเรียน',
        totalHourPerYear: 'จำนวนชั่วโมงเรียน / ปี',
        totalHourPerSession: 'จำนวนชั่วโมงเรียน / ภาคเรียน',
        credit: 'หน่วยกิต',
        sessionsPerWeek: 'จำนวนคาบ / สัปดาห์',
        schedulePattern: 'การแบ่งคาบบนตารางเรียน',
        attendPct: 'ต้องเข้าเรียนอย่างน้อย',
        selectable: 'นักเรียนสามารถเลือกเรียนได้',
        selectableNote: {
            text1: 'ทุกห้องที่เรียนวิชานี้',
            text2: 'ระบบจะจัดให้เรียนเวลาเดียวกัน'
        }
    },
    placeholder: {
        type: '- เลือกประเภท -',
        code: 'ระบุรหัสวิชา',
        name: 'ระบุชื่อวิชา',
        nameEn: 'ระบุชื่อวิชา',
        year: '- เลือกระดับชั้น -',
        semester: '- เลือกภาคเรียน -',
        totalHourPerYear: {
            title: 'เช่น 80',
            suffix: 'ชม. / ปี'
        },
        totalHourPerSession: {
            title: 'เช่น 80',
            suffix: 'ชม. / ภาคเรียน'
        },
        credit: '- เลือก -',
        sessionsPerWeek: '- เลือก -',
        schedulePattern: '- เลือก -',
        attendPct: '- เลือก -'
    },
    errorMessage: {
        required: 'จำเป็นต้องกรอก',
        correctFormat: {
            code: 'ต้องประกอบด้วย A-Z, a-z, 0-9 หรือ ก-ฮ',
            name: 'ต้องประกอบด้วย ก-ฮ ,a-z, 0-9 หรือ ช่องว่าง',
            nameEn: 'ต้องประกอบด้วย A-Z, a-z หรือ ช่องว่าง'
        },
        hours: 'ต้องมากกว่า 0 และไม่เกิน 3 หลัก'
    },
    note: 'คำแนะนำ : การตั้งค่าชื่อวิชาสำหรับการแสดงผลบนตารางเรียนเท่านั้น ครูผู้สอนสามารถตั้งรหัสวิชา, ชื่อวิชา และเลือกนักเรียนที่จะเรียนวิชานั้นได้ เมื่อเปิดภาคเรียน',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    duplicateCode: 'รหัสวิชานี้ใช้ในวิชาอื่นแล้ว'
}
