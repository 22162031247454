export default {
    semesterItems: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: 'ซัมเมอร์', value: 5 }
    ],
    creditItems: [
        { text: '0.5', value: 0.5 },
        { text: '1.0', value: 1 },
        { text: '1.5', value: 1.5 },
        { text: '2.0', value: 2 },
        { text: '2.5', value: 2.5 },
        { text: '3.0', value: 3 },
        { text: '3.5', value: 3.5 },
        { text: '4.0', value: 4 },
        { text: '4.5', value: 4.5 },
        { text: '5.0', value: 5 },
        { text: '10.0', value: 10 },
        { text: '20.0', value: 20 },
        { text: '30.0', value: 30 }
    ],
    sessionsPerWeekItems: [
        { text: '0', value: 0 },
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 }
    ],
    divOfSessionItems: [
        { text: '0', value: '0', sum: 0 },
        { text: '1', value: '1', sum: 1 },
        { text: '1+1', value: '1+1', sum: 2 },
        { text: '2', value: '2', sum: 2 },
        { text: '1+1+1', value: '1+1+1', sum: 3 },
        { text: '2+1', value: '2+1', sum: 3 },
        { text: '3', value: '3', sum: 3 },
        { text: '1+1+1+1', value: '1+1+1+1', sum: 4 },
        { text: '2+1+1', value: '2+1+1', sum: 4 },
        { text: '2+2', value: '2+2', sum: 4 },
        { text: '3+1', value: '3+1', sum: 4 },
        { text: '4', value: '4', sum: 4 },
        { text: '1+1+1+1+1', value: '1+1+1+1+1', sum: 5 },
        { text: '2+1+1+1', value: '2+1+1+1', sum: 5 },
        { text: '2+2+1', value: '2+2+1', sum: 5 },
        { text: '3+2', value: '3+2', sum: 5 },
        { text: '4+1', value: '4+1', sum: 5 },
        { text: '5', value: '5', sum: 5 },
        { text: '1+1+1+1+1+1', value: '1+1+1+1+1+1', sum: 6 },
        { text: '2+1+1+1+1', value: '2+1+1+1+1', sum: 6 },
        { text: '2+2+2', value: '2+2+2', sum: 6 },
        { text: '3+2+1', value: '3+2+1', sum: 6 },
        { text: '3+3', value: '3+3', sum: 6 },
        { text: '4+1+1', value: '4+1+1', sum: 6 },
        { text: '4+2', value: '4+2', sum: 6 },
        { text: '5+1', value: '5+1', sum: 6 },
        { text: '6', value: '6', sum: 6 }
    ],
    attendLessThanEqualItems: [
        { text: '100%', value: 100 },
        { text: '90%', value: 90 },
        { text: '80%', value: 80 },
        { text: '70%', value: 70 },
        { text: '60%', value: 60 },
        { text: '50%', value: 50 },
        { text: '40%', value: 40 },
        { text: '30%', value: 30 }
    ],
    studentYearPrimaryTh: [
        { text: 'ประถมศึกษาปีที่ 1', value: 1 },
        { text: 'ประถมศึกษาปีที่ 2', value: 2 },
        { text: 'ประถมศึกษาปีที่ 3', value: 3 },
        { text: 'ประถมศึกษาปีที่ 4', value: 4 },
        { text: 'ประถมศึกษาปีที่ 5', value: 5 },
        { text: 'ประถมศึกษาปีที่ 6', value: 6 }
    ],

    studentYearPrimaryEn: [
        { text: 'Grad 1', value: 1 },
        { text: 'Grad 2', value: 2 },
        { text: 'Grad 3', value: 3 },
        { text: 'Grad 4', value: 4 },
        { text: 'Grad 5', value: 5 },
        { text: 'Grad 6', value: 6 }
    ],

    studentYearMiddleTh: [
        { text: 'มัธยมศึกษาปีที่ 1', value: 7 },
        { text: 'มัธยมศึกษาปีที่ 2', value: 8 },
        { text: 'มัธยมศึกษาปีที่ 3', value: 9 }
    ],

    studentYearMiddleEn: [
        { text: 'Grad 7', value: 7 },
        { text: 'Grad 8', value: 8 },
        { text: 'Grad 9', value: 9 }
    ],

    studentYearHighTh: [
        { text: 'มัธยมศึกษาปีที่ 4', value: 10 },
        { text: 'มัธยมศึกษาปีที่ 5', value: 11 },
        { text: 'มัธยมศึกษาปีที่ 6', value: 12 }
    ],

    studentYearHighEn: [
        { text: 'Grad 10', value: 10 },
        { text: 'Grad 11', value: 11 },
        { text: 'Grad 12', value: 12 }
    ],

    activityCourseTypeTh: [
        { text: 'กิจกรรมแนะแนว', value: 'COUNSELLING_ACTIVITY' },
        { text: 'กิจกรรมนักเรียน', value: 'STUDENT_ACTIVITY' },
        { text: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์', value: 'ACTIVITY_FOR_SOCIAL' }
    ],

    activityCourseTypeEn: [
        { text: 'COUNSELLING ACTIVITY', value: 'COUNSELLING_ACTIVITY' },
        { text: 'STUDENT ACTIVITY', value: 'STUDENT_ACTIVITY' },
        { text: 'ACTIVITY FOR SOCIAL', value: 'ACTIVITY_FOR_SOCIAL' }
    ],

    subjectCourseTypeTh: [
        { text: 'พื้นฐาน', value: 'BASIC_COURSE' },
        { text: 'เพิ่มเติม', value: 'ADDITIONAL_COURSE' }
    ],

    subjectCourseTypeEn: [
        { text: 'BASIC COURSE', value: 'BASIC_COURSE' },
        { text: 'ADDITIONAL COURSE', value: 'ADDITIONAL_COURSE' }
    ]
}
