export default {
    header: 'ตารางสอน',
    contentTitle: 'ตารางสอน',
    label: {
        department: 'กลุ่มสาระทั้งหมด'
    },
    placeholder: {
        search: 'ค้นหาโดย รหัสครู / ชื่อครู'
    },
    noDepartment: 'ไม่ระบุกลุ่มสาระ',
    allDepartment: 'กลุ่มสาระทั้งหมด',
    table: {
        teacherNo: 'รหัสครู',
        fullName: 'ชื่อ - นามสกุล',
        departmentName: 'กลุ่มสาระ',
        totalPeriod: 'คาบสอน / สัปดาห์',
        noData: 'ไม่พบข้อมูล'
    },
    notes: [
        'พิมพ์ตารางสอนทั้งหมดได้รายกลุ่มสาระ'
    ]
}