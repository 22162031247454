export default{
    year:'ปีการศึกษา',
    firstTitle:'ข้อมูลทั่วไป',
    semester:'ภาคเรียน',
    department:'กลุ่มสาระการเรียนรู้',
    teacherNo:'รหัสครู',
    fullName:'ชื่อ - นามสกุล',
    timetable:'ตารางสอน',
    periodPerWeek:'จำนวนคาบสอน / สัปดาห์',
    secondContext1:'ระบบจะอ้างอิงข้อมูลรายวิชา, เวลาสอนจากตารางเรียน',
    secondContext2:'วิชาเลือก เมื่อเปิดภาคเรียน ผู้สอนนำรายชื่อนักเรียนส่งให้ครูวิชาการลงทะเบียน',
    secondContext3:'หมายเหตุจากครูวิชาการที่เพิ่มเข้ามา',
    summer:'Summer'
}