export default {
    title: 'จัดการตารางบันทึก',
    firstTitle: 'หัวข้อและข้อมูล',
    firstContext1: 'แสดงจุดทศนิยม (2 ตำแหน่ง)',
    firstContext2: 'แสดงหัวข้ออ่าน คิด วิเคราะห์ และเขียน',
    firstContext3: 'แสดงหัวข้อคุณลักษณะอันพึงประสงค์',
    secondaryTitle: 'ระดับผลการเรียน',
    secondaryContext1: 'ระบบตัวเลข',
    secondaryContext2: 'ระบบตัวอักษร',
    thirdTitle: 'กำหนดสัดส่วนคะแนน (ร้อยละ)',
    Acc: 'คะแนนเก็บ',
    bfMidAcc: 'ก่อนกลางภาค',
    afMidAcc: 'หลังกลางภาค',
    exam: 'คะแนนสอบ',
    midExamPct: 'กลางภาค',
    finExamPct: 'ปลายภาค',
    fourthTitle: 'กำหนดคะแนนดิบเต็ม',
    midExamTotal: 'สอบกลางภาค',
    finExamTotal: 'สอบปลายภาค',
    fifthTitle: 'ตั้งค่าใบงาน',
    nameAss: 'ชื่อใบงาน',
    nameAssPlaceholder: 'ระบุชื่อใบงาน',
    assTotal: 'คะแนนดิบเต็ม',
    assType: 'ประเภท',
    assTypePlaceholder: '- เลือก -',
    addAss: 'เพิ่มใบงาน',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    Over100Per: 'สัดส่วนทั้งหมดต้องไม่เกิน 100%',
    less100Per: 'สัดส่วนทั้งหมดต้องไม่น้อยกว่า 100%',
    emptyMid: 'ระบุคะแนนเต็มกลางภาค',
    emptyFin: 'ระบุคะแนนเต็มปลายภาค',
    type: {
        BEFORE_MIDTERM: 'เก็บก่อนกลางภาค',
        AFTER_MIDTERM: 'เก็บหลังกลางภาค'
    },
    success: 'บันทึกสำเร็จ',
    error: 'มีข้อผิดพลาด',
    isDupAsmName: 'ไม่สามารถใช้ชื่อใบงานซ้ำได้'
}