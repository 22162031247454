export default {
    header: 'กำหนดตาราง',
    conditionCard: {
        title: 'จัดการเงื่อนไขครู',
        btn: 'ตั้งค่าเงื่อนไข',
        texts: [
            'กำหนดเงื่อนไขที่ครูที่ต้องว่าง เช่น ว่างคาบที่ 1 ทุกวันจันทร์ ตามประเภทครูที่ถูกกำหนด ณ ขั้นตอนการเพิ่มครู เช่น หัวหน้ากลุ่มสาระ, ครูเวร, อื่นๆ',
            '** กรุณากำหนดเงื่อนทั้งหมดก่อนทำขั้นตอนที่ 2',
            'เนื่องจากระบบจะล็อคคาบที่ผู้สอนต้องว่าง ณ วันและเวลา นั้นก่อนจัดตารางเรียน / ตารางสอน'
        ]
    },
    stepCards: [
        {
            title: 'เวลาเรียนแต่ละห้อง',
            text: 'กำหนดเวลาเริ่มเรียน - เลิกเรียน เพื่อนำ รายวิชาที่โรงเรียนกำหนดมาใส่ลงในตารางเรียน',
            btn: {
                first: {
                    title: 'กำหนดเวลาเรียนรายห้อง'
                }
            }
        },
        {
            title: 'วิชาเรียนเวลาเดียวกัน',
            text: 'สำหรับตั้งค่ากิจกรรมพัฒนาผู้เรียน หรือรายวิชา ที่ต้องเรียนรวมเวลาเดียวกัน',
            btn: {
                first: {
                    title: 'กำหนดวิชาเรียนเวลาเดียวกัน'
                },
                skip: {
                    title: 'ข้ามขั้นตอนนี้'
                }
            }
        },
        {
            title: 'ครูผู้สอน',
            texts: [
                'กำหนดครูผู้สอนตามรายวิชา และห้องสอน เพื่อกระจายคาบเรียน',
                'เฉพาะที่ไม่ใช่วิชาเลือก'
            ],
            btn: {
                first: {
                    title: 'กำหนดครูผู้สอน'
                }
            }
        },
        {
            title: 'ตารางเรียน / สอน',
            btn: {
                first: {
                    title: 'จัดตารางเรียน'
                },
                second: {
                    title: 'ดูตารางสอน'
                }
            }
        }
    ],
    progressTitle: 'จำนวนตารางเรียนที่ต้องจัด',
    btn: 'แจ้งตารางเรียนตารางสอน',
    modal: {
        header: 'ข้ามขั้นตอนตั้งค่าเวลาเรียนรวม ?',
        content: [
            'หลังจากยืนยันจะไม่สามารถตั้งค่า',
            'วิชาเรียนรวมได้อีก'
        ]
    },
    notify: {
        start: 'เริ่มใช้งานตารางเรียน / สอน',
        skip: 'ข้ามขั้นตอน "กำหนดวิชาเรียนรวม"'
    },
    notes: [
        'หากมีวิชาเลือกเรียนได้ จะไม่สามารถข้ามขั้นตอนนี้'
    ]
}
