export default {
    title: 'กรุณาเลือกรายชื่อนักเรียนที่ขาดเรียน',
    searchPlaceholder: 'ค้นหา รหัสนักเรียน / ชื่อนักเรียน',
    secMember: 'เลขที่',
    studentNo: 'รหัสนักเรียน',
    absent: 'ขาดเรียน',
    present: 'มา',
    totalAbsent: 'ชั่วโมงขาด',
    absentType: {
        SICK_LEAVE: 'ลาป่วย',
        PERSONAL_LEAVE: 'ลากิจ',
        SKIP: 'หนี'
    },
    noteCondition: 'ระบุได้ไม่เกิน 30 ตัวอักษร',
    notePlaceholder: 'หมายเหตุ (ถ้ามี)',
    save: 'บันทึก',
    notFound: 'ไม่พบข้อมูลนักเรียน',
    saveSuccess: 'บันทึกสำเร็จ',
    saveFail: 'ไม่สำเร็จ กรุณาตรวจสอบข้อมูล',
    hour: 'ชม.',
}