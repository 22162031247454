export default {
    getSemester (semester, lang) {
        if (semester === 5) {
            if (lang === 'th') {
                return 'ซัมเมอร์'
            }
            return 'summer'
        }
        return semester
    }
}
