export default {
    title: 'กำหนดการ',
    year: 'ปีการศึกษา',
    detail: 'ครูผู้สอนสามารถบันทึกเวลาขาดเรียนได้ตั้งแต่ วันเปิดภาคเรียน - วันส่งผลรอบปกติ',
    recordStartDate: 'เริ่มบันทึกผล',
    tooltipRecordStart: 'ครูผู้สอนเริ่มบันทึกผลการเรียนตั้งแต่วันที่กำหนด',
    recordEndDate: 'ส่งผลรอบปกติ',
    tooltipRecordEnd: 'ครูผู้สอนต้องส่งบันทึกผลการเรียน ภายในวันที่กำหนด หลังจากนั้นจะไม่สามารถแก้ไขผลได้',
    specialDate: 'แก้คะแนนรอบพิเศษ',
    tooltipSpecialDate: 'ช่วงเวลาการแก้ไขคะแนนรอบพิเศษในกรณี นักเรียนติด 0, ร., มส., มผ.',
    announceDate: 'ประกาศผล',
    tooltipAnnounceDate: 'กำหนดการประกาศผลการเรียนผ่านไลน์ ให้นักเรียนและผู้ปกครอง',
    summer: 'ซัมเมอร์',
    header: 'บันทึกเวลาขาดเรียน',
    detailScoring: 'หากครูผู้สอนยังไม่บันทึกผลการเรียน เมื่อถึงวันกำหนดการส่งคะแนน ระบบจะส่งบันทึกผลการเรียนให้ฝ่ายวิชาการโดยอัตโนมัติ',
}