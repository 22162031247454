import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import validator from './plugins/validator'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import dateUtil from './dateUtil'
import studentYearUtil from './studentYearUtil'
import departmentCourseUtil from './departmentCourseUtil'
import semesterUtil from './semesterUtil'
import timeUtil from './timeUtil'
import VueAxios from 'vue-axios'
import IdleVue from 'idle-vue'
import mixin from './mixins/globalMixin'

import departmentCourseFormConstant from './constants/departmentCourseForm'
import timetableTemplateConstant from './constants/timetableTemplate'
import oneUserAccessModuleConstant from './constants/oneUserAccessModule'
import VueHtml2pdf from 'vue-html2pdf'
import 'core-js/actual'
import axios from './axios'
import liffUtil from './liffUtil'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

const plugin = {
    install (Vue) {
        Vue.prototype.$validator = validator
        Vue.prototype.$dateUtil = dateUtil
        Vue.prototype.$liffUtil = liffUtil
        Vue.prototype.$studentYearUtil = studentYearUtil
        Vue.prototype.$departmentCourseUtil = departmentCourseUtil
        Vue.prototype.$semesterUtil = semesterUtil
        Vue.prototype.$constant = {
            departmentCourseForm: departmentCourseFormConstant,
            timetableTemplate: timetableTemplateConstant,
            oneUserAccessModule: oneUserAccessModuleConstant
        }
        Vue.prototype.$timeUtil = timeUtil
        // Vue.prototype.$constant = { departmentCourseForm: departmentCourseFormConstant, timetableTemplate: timetableTemplateConstant }
    }
}
Vue.use(plugin)
Vue.use(Notifications)
Vue.mixin(mixin)
Vue.use(IdleVue, {
    store,
    idleTime: 1.8e+6, // 30 min
    // idleTime: 10000, // 30 min
    startAtIdle: false
})
Vue.use(VueHtml2pdf)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
