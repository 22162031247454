<template>
    <div id="loading">
        <v-progress-circular
            :size=80
            :width="7"
            :value="value"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    data () {
        return {
            interval: {},
            value: 0
        }
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    mounted () {
        this.interval = setInterval(() => {
            if (this.value === 100) {
                return (this.value = 0)
            }
            this.value += 20
        }, 1000)
    }
}
</script>

<style lang="scss" scoped>
    #loading {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        min-height: fit-content;
        position: fixed;
        z-index: 250;
        // position: absolute;
        background-color: #23232347;
        height: 100%;
        color: $onyx;
    }
</style>
