export default {
    header: 'จัดตารางเรียน',
    contentTitle: 'จัดตารางเรียน',
    label: {
        studentYear: 'ระดับชั้นทั้งหมด'
    },
    placeholder: {
        search: 'ค้นหาโดย ห้องเรียน / แผนการเรียน'
    },
    table: {
        classroom: 'ระดับชั้น / ห้อง',
        programName: 'แผนการเรียน',
        totalCourse: 'จำนวนวิชา',
        status: 'สถานะ',
        complete: 'สำเร็จ',
        draft: 'ไม่สำเร็จ',
        noData: 'ไม่พบข้อมูล'
    },
    notes: [
        'กรุณากดสร้างตารางเรียนทีละห้อง',
        'พิมพ์ตารางเรียนทั้งหมดได้รายระดับชั้น'
    ]
}