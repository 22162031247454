const shortName = ['อ.1', 'อ.2', 'อ.3', 'ป.1', 'ป.2', 'ป.3', 'ป.4', 'ป.5', 'ป.6', 'ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6']
const fullName = [
    'อนุบาล 1', 'อนุบาล 2', 'อนุบาล 3', 'ประถมศึกษาปีที่ 1', 'ประถมศึกษาปีที่ 2', 'ประถมศึกษาปีที่ 3', 'ประถมศึกษาปีที่ 4', 'ประถมศึกษาปีที่ 5',
    'ประถมศึกษาปีที่ 6', 'มัธยมศึกษาปีที่ 1', 'มัธยมศึกษาปีที่ 2', 'มัธยมศึกษาปีที่ 3', 'มัธยมศึกษาปีที่ 4', 'มัธยมศึกษาปีที่ 5', 'มัธยมศึกษาปีที่ 6'
]

const kindergarten = [99, 98, 97]

export default {

    getFullStudentYear (year) {
        let yearString = String
        if (kindergarten.includes(year)) {
            if (year === 99) {
                yearString = fullName[0]
            } else if (year === 98) {
                yearString = fullName[1]
            } else {
                yearString = fullName[2]
            }
        } else {
            const index = year + 2
            yearString = fullName[index]
        }

        return yearString
    },

    getShortStudentYear (year) {
        let yearString = String
        if (kindergarten.includes(year)) {
            if (year === 99) {
                yearString = shortName[0]
            } else if (year === 98) {
                yearString = shortName[1]
            } else {
                yearString = shortName[2]
            }
        } else {
            const index = year + 2
            yearString = shortName[index]
        }

        return yearString
    },

    studentClassroomToString (year, room) {
        return `${this.getShortStudentYear(year)}/${room}`
    },

    getStudentLevel (level, lang) {
        let levelText = ''
        if (level === 'HIGH') {
            if (lang === 'th') {
                levelText = 'มัธยมศึกษาตอนปลาย'
            } else {
                levelText = 'HIGH SCHOOL'
            }
        } else if (level === 'MIDDLE') {
            if (lang === 'th') {
                levelText = 'มัธยมศึกษาตอนต้น'
            } else {
                levelText = 'MIDDLE SCHOOL'
            }
        } else {
            if (lang === 'th') {
                levelText = 'ประถมศึกษา'
            } else {
                levelText = 'PRIMARY SCHOOL'
            }
        }
        return levelText
    }

}
