export default {
    header:'ตารางสอน',
    year:'ปีการศึกษา',
    semester:'ภาคเรียน',
    periodPerWeek:'คาบสอน / สัปดาห์',
    updated:'อัปเดตล่าสุด',
    searchPlaceHolder:'ค้นหาโดย ปีการศึกษา',
    firstEmptyTable:'ครูวิชาการกำลังจัดตารางเรียน / สอน',
    secondaryEmptyTable:'หลังจากจัดตารางสำเร็จ ',
    thirdEmptyTable:'ระบบจะแสดงรายการให้โดยอัตโนมัติ',
    search:'ค้นหา',
    summer:'ซัมเมอร์',
    tooltipYear:'ระบบจะแสดงรายการให้ โดยอัตโนมัติ โดยอ้างอิงปีการศึกษา และภาคเรียน ครูวิชาการจัดตารางเรียน/สอน แล้ว',
    notFound:'ไม่พบข้อมูล'
}