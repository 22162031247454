export default {
    info: {
        header: 'ข้อมูลทั่วไป',
        title: {
            academicYear: 'ปีการศึกษา',
            semester: 'ภาคเรียน',
            totalPeriod: 'จำนวนคาบสอน / สัปดาห์',
            departmentName: 'กลุ่มสาระการเรียนรู้',
            teacherNo: 'รหัสครู',
            fullName: 'ชื่อ - นามสกุล'
        }
    },
    note: {
        header: 'ตารางสอน',
        title: {
            additionalInfo: 'การใส่ข้อมูลเพิ่มเติม'
        },
        content: [
            'ระบบจะอ้างอิงข้อมูลรายวิชา, เวลาสอนจากตารางเรียน',
            '1. กดปุ่ม “เพิ่มหมายเหตุ” เพื่อใส่หมายเหตุเพิ่มเติม เช่น ประชุมกลุ่มสาระ',
            '2. กดปุ่ม “บันทึก” เพื่อยืนยันข้อมูล'
        ]
    }
}