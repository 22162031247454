export default {
    electiveCourse:'ลงทะเบียนวิชาเลือก',
    year:'ปีการศึกษา',
    titleDetail: 'ขั้นตอนการลงทะเบียน',
    firstContext:'วิชาที่ต้องการลงทะเบียนตามระดับชั้น',
    secondaryContext:'ระบุชื่อวิชาทีแสดงบนใบเกรด (ถ้ามี)',
    thirdContext:'เลือกห้องเรียนและรายชื่อนักเรียนที่ต้องการลงทะเบียนในวิชานั้น',
    fourthContext:"เลือกครูผู้สอน ที่ต้องสอนนักเรียนกลุ่มนั้น",
    click:'กดปุ่ม',
}
