export default {
    addCurriculum: 'เพิ่มหลักสูตร',
    addYear: 'ระบุปีการศึกษา',
    chooseYear: '- เลือกปีการศึกษา -',
    checkCopy: 'คัดลอกรายวิชาในแผนการเรียนจากปีการศึกษาที่เคยมี แล้วทำการแก้ไข',
    cancel: 'ยกเลิก',
    next: 'ถัดไป',
    notify: {
        title: 'สร้างหลักสูตร'
    }
}
