export default {
    timetable: 'ตารางเรียน',
    generalInfo: 'ข้อมูลทั่วไป',
    academicYear: 'ปีการศึกษา',
    semester: 'ภาคเรียน',
    academicProgram: 'แผนการเรียน',
    classRoom: 'ระดับชั้น / ห้อง',
    className: 'ชื่อห้อง',
    classTeachers: 'ครูประจำชั้น',
    headerInfo: 'การจัดตารางเรียน',
    textInfo: 'ระบบจะอ้างอิงข้อมูลรายวิชา, เวลาเรียน และครูผู้สอนจากการตั้งค่าที่ผู้ใช้งานกำหนด',
    textProcess: 'ขั้นตอนการสร้างตารางเรียน',
    // firstProcess: '1. กดปุ่ม “สร้างตารางเรียน” ระบบจะจัดตารางเรียนให้โดยอัตโนมัติ',
    // firstSubProcess: '1.1   กรณีต้องการเปลี่ยนตารางเรียนกด “สร้างตารางเรียน” อีกครั้ง',
    firstProcessFirstPart: '1. กดปุ่ม “',
    firstProcessSecondPart: '” เพื่อเลือกวิชา',
    secondProcess: '2. กดปุ่ม “ยืนยัน” เพื่อยืนยันข้อมูล',
    clearAndEdit: 'ล้างข้อมูลและแก้ไขเอง',
    createTimetable: 'สร้างตารางเรียน',
    cancel: 'ยกเลิก',
    save: 'บันทึก',
    courseInfo: 'ข้อมูลรายวิชา',
    code: 'รหัสวิชา',
    courseName: 'ชื่อวิชา',
    courseType: 'ประเภท',
    hrPerYear: 'ชม./ปี',
    periods: 'จำนวนคาบ',
    instructors: 'ครูผู้สอน',
    tableInfo: 'ระบบจะไม่แสดงวิชาที่ถูกตั้งค่าให้ไม่ต้องแสดงบนตารางเรียน',
    totalCourses: 'จำนวนวิชาทั้งหมด',
    totalPeriods: 'จำนวนคาบ',
    basicCourse: 'รายวิชาพื้นฐาน',
    additionalCourse: 'รายวิชาเพิ่มเติม',
    activityCourse: 'กิจกรรม',
    totalHours: 'รวมเวลาเรียนทั้งสิ้น',
    courseUnit: 'วิชา',
    periodUnit: 'คาบ / สัปดาห์',
    hrYearUnit: 'ชม./ปี',
    hrSemUnit: 'ชม./ภาคเรียน',
    header: 'จัดตารางเรียน',
    teacherNotAvailable: 'ครูผู้สอนไม่ว่าง ณ คาบที่เลือก',
    somethingWentWrong: 'เกิดข้อผิดพลาด',
    pleaseCheckData: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
    period: 'คาบ',
    success: 'สำเร็จ',
    lunchTime: 'Lunch Time',
    status: 'สถานะ',
    successBtn: 'สำเร็จ'
}
