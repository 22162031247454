export default {
    title: 'เลือกรายชื่อนักเรียน',
    classroom: 'เวลาเรียน',
    stdList: 'รายชื่อนักเรียน',
    gradeNo: 'รหัสวิชาบนใบเกรด',
    roomPlaceHolder: 'เลือกเวลาเรียน',
    namePlaceHolder: 'ระบุชื่อวิชา',
    nameTH: 'ชื่อวิชา (ไทย)',
    nameEN: 'ชื่อวิชา (อังกฤษ)',
    gradePlaceHolder: 'ระบุรหัสวิชา',
    studentPlaceHolder: '- เลือกนักเรียน -',
    addList: 'เพิ่มรายชื่อ',
    room: 'ห้อง',
    codeStudent: 'รหัสนักเรียน',
    nameSurname: 'ชื่อ - นามสกุล',
    firstTextEmpty: 'เลือกนักเรียน',
    selectAll: 'เลือกทั้งหมด',
    studentTotal: 'จำนวนทั้งหมด',
    person: 'คน',
    titleFourth: 'เลือกครู',
    period: 'คาบ',
    time: 'เวลา',
    selectTeacher: 'เลือกครูผู้สอน',
    save: 'บันทึก',
    studentDup: 'ลงทะเบียนซ้ำ',
    teacherDup: 'ครูผู้สอนไม่ว่าง ณ คาบที่เลือก',
    addTeacher: 'เพิ่มครูผู้สอน'
}