export default {
    firstPeriodItems: [
        { text: '06:00', value: '06:00' },
        { text: '06:15', value: '06:15' },
        { text: '06:30', value: '06:30' },
        { text: '06:45', value: '06:45' },
        { text: '07:00', value: '07:00' },
        { text: '07:15', value: '07:15' },
        { text: '07:30', value: '07:30' },
        { text: '07:45', value: '07:45' },
        { text: '07:50', value: '07:50' },
        { text: '08:00', value: '08:00' },
        { text: '08:15', value: '08:15' },
        { text: '08:30', value: '08:30' },
        { text: '08:45', value: '08:45' },
        { text: '09:00', value: '09:00' },
        { text: '09:15', value: '09:15' },
        { text: '09:30', value: '09:30' },
        { text: '09:45', value: '09:45' },
        { text: '10:00', value: '10:00' },
        { text: '10:15', value: '10:15' },
        { text: '10:30', value: '10:30' },
        { text: '10:45', value: '10:45' },
        { text: '11:00', value: '11:00' },
        { text: '11:15', value: '11:15' },
        { text: '11:30', value: '11:30' },
        { text: '11:45', value: '11:45' },
        { text: '12:00', value: '12:00' },
        { text: '12:15', value: '12:15' },
        { text: '12:30', value: '12:30' },
        { text: '12:45', value: '12:45' },
        { text: '13:00', value: '13:00' },
        { text: '13:15', value: '13:15' },
        { text: '13:30', value: '13:30' },
        { text: '13:45', value: '13:45' },
        { text: '14:00', value: '14:00' },
        { text: '14:15', value: '14:15' },
        { text: '14:30', value: '14:30' },
        { text: '14:45', value: '14:45' },
        { text: '15:00', value: '15:00' }
    ],

    periodTimeItemsTh: [
        { text: '30 นาที', value: 30 },
        { text: '35 นาที', value: 35 },
        { text: '40 นาที', value: 40 },
        { text: '45 นาที', value: 45 },
        { text: '50 นาที', value: 50 },
        { text: '55 นาที', value: 55 },
        { text: '60 นาที', value: 60 },
        { text: '75 นาที', value: 75 },
        { text: '80 นาที', value: 80 },
        { text: '85 นาที', value: 85 },
        { text: '90 นาที', value: 90 },
        { text: '95 นาที', value: 95 }
    ],
    periodTimeItemsEn: [
        { text: '30 mins', value: 30 },
        { text: '35 mins', value: 35 },
        { text: '40 mins', value: 40 },
        { text: '45 mins', value: 45 },
        { text: '50 mins', value: 50 },
        { text: '55 mins', value: 55 },
        { text: '60 mins', value: 60 },
        { text: '75 mins', value: 75 },
        { text: '80 mins', value: 80 },
        { text: '85 mins', value: 85 },
        { text: '90 mins', value: 90 },
        { text: '95 mins', value: 95 }
    ],
    periodBreakItemsTh: [
        { text: '5 นาที', value: 5 },
        { text: '10 นาที', value: 10 },
        { text: '15 นาที', value: 15 },
        { text: '20 นาที', value: 20 },
        { text: '25 นาที', value: 25 },
        { text: '30 นาที', value: 30 }
    ],
    periodBreakItemsEn: [
        { text: '5 mins', value: 5 },
        { text: '10 mins', value: 10 },
        { text: '15 mins', value: 15 },
        { text: '20 mins', value: 20 },
        { text: '25 mins', value: 25 },
        { text: '30 mins', value: 30 }
    ],
    afterPeriodItemsTh: [
        { text: 'หลังคาบที่ 1', value: 1, isSelect: false },
        { text: 'หลังคาบที่ 2', value: 2, isSelect: false },
        { text: 'หลังคาบที่ 3', value: 3, isSelect: false },
        { text: 'หลังคาบที่ 4', value: 4, isSelect: false },
        { text: 'หลังคาบที่ 5', value: 5, isSelect: false },
        { text: 'หลังคาบที่ 6', value: 6, isSelect: false },
        { text: 'หลังคาบที่ 7', value: 7, isSelect: false },
        { text: 'หลังคาบที่ 8', value: 8, isSelect: false },
        { text: 'หลังคาบที่ 9', value: 3, isSelect: false },
        { text: 'หลังคาบที่ 10', value: 10, isSelect: false }
    ],
    afterPeriodItemsEn: [
        { text: 'After Period 1', value: 1, isSelect: false },
        { text: 'After Period 2', value: 2, isSelect: false },
        { text: 'After Period 3', value: 3, isSelect: false },
        { text: 'After Period 4', value: 4, isSelect: false },
        { text: 'After Period 5', value: 5, isSelect: false },
        { text: 'After Period 6', value: 6, isSelect: false },
        { text: 'After Period 7', value: 7, isSelect: false },
        { text: 'After Period 8', value: 8, isSelect: false },
        { text: 'After Period 9', value: 3, isSelect: false },
        { text: 'After Period 10', value: 10, isSelect: false }
    ],
    durationItemsTh: [
        { text: '5 นาที', value: 5 },
        { text: '10 นาที', value: 10 },
        { text: '15 นาที', value: 15 },
        { text: '20 นาที', value: 20 },
        { text: '25 นาที', value: 25 },
        { text: '30 นาที', value: 30 },
        { text: '35 นาที', value: 35 },
        { text: '40 นาที', value: 40 },
        { text: '45 นาที', value: 45 }
    ],
    durationItemsEn: [
        { text: '5 mins', value: 5 },
        { text: '10 mins', value: 10 },
        { text: '15 mins', value: 15 },
        { text: '20 mins', value: 20 },
        { text: '25 mins', value: 25 },
        { text: '30 mins', value: 30 },
        { text: '35 mins', value: 35 },
        { text: '40 mins', value: 40 },
        { text: '45 mins', value: 45 }
    ],
    lunchTimeOptionsTh: [
        { text: '5 นาที', value: 5 },
        { text: '10 นาที', value: 10 },
        { text: '15 นาที', value: 15 },
        { text: '20 นาที', value: 20 },
        { text: '25 นาที', value: 25 },
        { text: '30 นาที', value: 30 },
        { text: '35 นาที', value: 35 },
        { text: '40 นาที', value: 40 },
        { text: '45 นาที', value: 45 },
        { text: '50 นาที', value: 50 },
        { text: '55 นาที', value: 55 },
        { text: '60 นาที', value: 60 },
        { text: '65 นาที', value: 65 },
        { text: '70 นาที', value: 70 },
        { text: '75 นาที', value: 75 },
        { text: '80 นาที', value: 80 },
        { text: '85 นาที', value: 85 },
        { text: '90 นาที', value: 90 },
        { text: '95 นาที', value: 95 }
    ],
    lunchTimeOptionsEh: [
        { text: '5 mins', value: 5 },
        { text: '10 mins', value: 10 },
        { text: '15 mins', value: 15 },
        { text: '20 mins', value: 20 },
        { text: '25 mins', value: 25 },
        { text: '30 mins', value: 30 },
        { text: '35 mins', value: 35 },
        { text: '40 mins', value: 40 },
        { text: '45 mins', value: 45 },
        { text: '50 mins', value: 50 },
        { text: '55 mins', value: 55 },
        { text: '60 mins', value: 60 },
        { text: '65 mins', value: 65 },
        { text: '70 mins', value: 70 },
        { text: '75 mins', value: 75 },
        { text: '80 mins', value: 80 },
        { text: '85 mins', value: 85 },
        { text: '90 mins', value: 90 },
        { text: '95 mins', value: 95 }
    ]
}
