export default {
    titleSecondary: 'กำหนดตารางบันทึก',
    secondaryDetail1: 'กำหนดสัดส่วนคะแนน (ร้อยละ)',
    secondaryDetail2: 'กำหนดคะแนนดิบเต็ม สอบกลางภาค, สอบปลายภาค',
    secondaryDetail3: 'เพิ่มใบงาน (คะแนนเก็บ) ระบุคะแนนดิบเต็ม',
    editBtn: 'จัดการตาราง',
    titleThird: 'ระดับผลการเรียน',
    number: 'ตัวเลข',
    character: 'ตัวอักษร',
    percentage: 'ร้อยละ',
    word: 'คำ',
    excellent: 'ดีเยี่ยม',
    good: 'ดี',
    pass: 'ผ่าน',
    fail: 'ไม่ผ่าน',
    gradDetail1: 'มส คือ ไม่มีสิทธิ์สอบ',
    gradDetail2: 'ร คือ รอการตัดสินผลการเรียน'
}