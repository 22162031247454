export default {
    title: 'รายการวิชาเลือก',
    code: 'รหัสวิชา',
    name: 'ชื่อวิชา',
    type: 'ประเภท',
    level: 'ระดับชั้น',
    registered: 'ลงทะเบียน',
    typePlaceholder: 'ประเภททั้งหมด',
    levelPlaceholder: 'ระดับชั้นทั้งหมด',
    secondarySchool: 'Secondary School',
    primarySchool: 'ประถมศึกษา',
    search: 'ค้นหา',
    searchPlaceHolder: 'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    firstTextEmpty:'ไม่พบรายวิชาที่ต้องสอน',
    courseType: {
        BASIC_COURSE: 'Required subject',
        ADDITIONAL_COURSE: 'Selective subject',
        COUNSELLING_ACTIVITY: 'Counselling activities',
        STUDENT_ACTIVITY: 'Student activities',
        ACTIVITY_FOR_SOCIAL: 'Activities for social and public interst'
    },
    searchAcademicYearPlaceHolder: 'ค้นหาโดย ปีการศึกษา',
    notFound:'Search Not found'
}
