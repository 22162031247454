export default {
    header: 'ปีการศึกษา',
    title: 'ขั้นตอนการจัดหลักสูตร',
    content: {
        1: ['กดปุ่ม', 'ที่แผนการเรียนที่ต้องการจัดการวิชา'],
        2: 'ระบุวิชาทั้งหมดที่ต้องเรียนในแต่ละระดับชั้น',
        3: 'เมื่อระบุข้อมูลทั้งหมดแล้ว กดปุ่ม “สำเร็จ”'
    },
    successButton: 'สำเร็จ',
    note: 'กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนกดปุ่มสำเร็จหลังจากนั้นจะไม่สามารถแก้ไขข้อมูลได้เนื่องจากระบบจะนำข้อมูลจากหลักสูตรไปจัดตารางเรียน',
    countSuccessTitle: 'จำนวนแผนการเรียนที่สำเร็จ',
    searchPlaceholder: 'ค้นหาโดย แผนการเรียน',
    yearFilterLabel: 'ระดับชั้นทั้งหมด',
    statusFilterLabel: 'สถานะทั้งหมด',
    studyPlanStatus: {
        success: 'สำเร็จ',
        draft: 'ฉบับร่าง'
    },
    emptyTable: 'ไม่พบแผนการเรียน',
    warningModalContent: [
        'หลังจากยืนยันจะไม่สามารถแก้ไขข้อมูลได้',
        'เนื่องจากระบบจะนำข้อมูลจากหลักสูตรไปจัดตารางเรียน'
    ],
    warningModalHeader: 'ยืนยันจัดการหลักสูตรสำเร็จ ?',
    table: {
        name: 'แผนการเรียน',
        studentYear: 'ระดับชั้น',
        courseCount: 'จำนวนวิชา',
        status: 'สถานะ',
        updatedDate: 'อัปเดตล่าสุด'
    },
    notify: {
        title: 'เปลี่ยนสถานะ'
    }
}
