export default {
    simultaneous: 'วิชาเรียนเวลาเดียวกัน',
    emptyFirstText: 'ไม่พบวิชาเรียนรวม',
    emptySecondText: 'ในภาคเรียนนี้',
    department: 'กลุ่มสาระการเรียนรู้',
    selectDepartment: '-เลือกกลุ่มสาระการเรียนรู้-',
    course: 'วิชา',
    selectCourse: '-เลือกวิชา-',
    classroom: 'ระดับชั้น / ห้องเรียน',
    selectClassroom: '-เลือกห้องเรียน-',
    addClassroom: 'เพิ่มห้องเรียน',
    totalClassroom: 'จำนวนห้องทั้งหมด',
    room: 'ห้อง',
    timeToStudy: 'เวลาเรียน',
    day: 'วัน',
    selectDay: '-เลือกวัน-',
    period: 'คาบที่',
    totalPeriod: 'จำนวนคาบ',
    unit: 'คาบ',
    selectPeriod: '-เลือกคาบ-',
    confirm: 'ยืนยัน',
    cancel: 'ยกเลิก',
    studentYear: 'ระดับชั้น',
    studentProgram: 'แผนการเรียน',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัสบดี',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์',
    sunday: 'วันอาทิตย์',
    selectable: 'วิชาเลือก',
    templateName: 'เวลาเรียนค่าตั้งต้น',
    selectTemplate: '-เลือกเวลาเรียนค่าตั้งต้น-',
    selectCourseClassroom: 'เลือกวิชาและห้องเรียน',
    toSimultaneous: 'ที่เรียนรวมเวลาเดียวกัน',
    errorMassage: 'ตารางเรียนนักเรียนว่างไม่ตรงกัน',
    addPeriod: 'เพิ่มคาบเรียน',
    periodHolder: '-เลือกคาบ-',
    errorDupMessage: 'กรุณาเลือกเวลาใหม่'
}
