export default{
    name:'ประเภทครู',
    totalTeacher:'จำนวนครู',
    period:'คาบ',
    date:'วัน',
    description:'หมายเหตุ',
    search:'ค้นหา',
    searchPlaceHolder:'ค้นหาโดย ประเภทครู',
    addCondition:'เพิ่มเงื่อนไข',
    emptyText:'กรุณาเพิ่มเงื่อนไขครู (ถ้ามี)',
    notFound:'ไม่พบข้อมูล',
    modalCondition:'เงื่อนไขว่าง',
    namePlaceHolder:'- เลือกประเภทครู -',
    periodPlaceHolder:'- เลือกคาบ -',
    datePlaceHolder:'- เลือกวัน -',
    descriptionModal:'หมายเหตุ (ถ้ามี)',
    descriptionPlaceHolder:'ระบุหมายเหตุ',
    save:'บันทึก',
    cancel:'ยกเลิก',
    add:'เพิ่ม',
    titleDel:'ต้องการลบเงื่อนไข?',
    detailDel1:'ประเภทครู',
    detailDel2:'เงื่อนไขจะถูกลบ และต้องตั้งค่าใหม่ทั้งหมด',
    confirm:'ยืนยัน',
}