import Vue from 'vue'
import Vuex from 'vuex'

import axios from '@/axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        breadcrumbs: [],
        historyRoutes: {},
        loading: false,
        loadingBounce: null,
        test: 'test',
        sessions: {
            primKey: null,
            moduleName: null,
            session: null
        },
        warning: {
            access: false,
            timeout: false,
            userInUsed: null,
        },
        timetable: {
            id: null,
            info: null
        },

        confirmLeave: {
            dialog: false
        },

        hasChange: false,
        changedDataList: [],
        pathSave: '',
        confirmSave: false,
        nextRoute: {},
        isValid: true,
        text: '',
        isPathChange: false
    },
    mutations: {
        setBreadCrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs
        },
        setLoading(state, payload) {
            if (state.loadingBounce) {
                clearTimeout(state.loadingBounce)
            }

            if (payload) {
                state.loading = payload
            } else {
                state.loadingBounce = setTimeout(() => {
                    state.loading = payload
                }, 200)
            }
        },
        addHistoryRoute(state, route) {
            state.historyRoutes[route.name] = route
        },
        setSessions(state, payload) {
            state.sessions.moduleName = payload.moduleName
            state.sessions.primKey = payload.primKey
            state.sessions.session = (Date.now()).toString()
        },

        openAccessModal(state, type) {
            if (type === 'access') {
                state.warning.access = true
            } else {
                state.warning.timeout = true
            }
        },
        closeAccessModal(state) {
            state.warning.access = false
            state.warning.timeout = false
            state.warning.userInUsed = null
        },
        // openConfirmSave(state) {
        //     state.confirmSave = true
        // },
        // closeConfirmSave(state) {
        //     state.confirmSave = false
        // },
        
        // Confirm Leave Modal
        openConfirmLeaveModal(state) {
            state.confirmLeave.dialog = true
        },
        closeConfirmLeaveModal(state) {
            state.confirmLeave.dialog = false
        },

        setTimetableStep(state, newStep) {
            state.timetable.info.step = newStep
        },
        setHasChanged(state, newVal) {
            state.hasChange = newVal
        },
        // reSetHasChanged(state) {
        //     state.hasChange = false
        // },
        // setIsPathChange(state) {
        //     state.isPathChange = true
        // },
        // resetIsPathChange(state) {
        //     state.isPathChange = false
        // },
        // setChangedDataList(state, payload) {
        //     state.changedDataList = payload.datalist
        //     state.pathSave = payload.path
        //     if (payload.isValid !== undefined) {
        //         state.isValid = payload.isValid
        //         state.text = payload.text
        //     }
        // },
        // reSetChangedDataList(state) {
        //     state.changedDataList = []
        //     state.pathSave = ''
        //     state.hasChange = false
        //     state.isValid = true
        //     state.text = ''
        // },

        setRoute(state, route) {
            state.nextRoute = route
        },

        resetRoute(state) {
            state.nextRoute = {}
        },
        setAccessModal(state, payload) {
            state.warning.userInUsed = payload.userInUsed
            state.warning.access = payload.type === "access"
        },

        // clearHistoryRoutes (state) {
        //     state.historyRoutes = {}
        // }
    },
    actions: {
        // setHasChanged({ commit }) {
        //     commit('setHasChanged')
        // },
        // reSetHasChanged({ commit }) {
        //     commit('reSetHasChanged')
        // },
        setRoute({ commit }, route) {
            commit('setRoute', route)
        },
        reSetRoute({ commit }) {
            commit('reSetRoute')
        },
        // reSetChangedDataList({ commit }) {
        //     commit('reSetChangedDataList')
        // },
        // setChangedDataList({ commit }, payload) {
        //     commit('setChangedDataList', payload)
        // },
        // openConfirmSave({ commit }) {
        //     commit('openConfirmSave')
        // },
        // closeConfirmSave({ commit }) {
        //     commit('closeConfirmSave')
        // },
        setLoading({ commit }, payload) {
            commit('setLoading', payload)
        },
        setSessions({ commit }, payload) {
            commit('setSessions', payload)
        },
        openAccessModal({ commit }, payload) {
            commit('openAccessModal', payload)
        },
        closeAccessModal({ commit }) {
            commit('closeAccessModal')
        },
        setAccessModal({ commit }, payload) {
            commit('setAccessModal', payload)
        },
        async setTimetableInfo({ state, commit }, { timetableId, force = false }) {
            if (!state.timetable || state.timetable.id !== timetableId || force) {
                commit('setLoading', true)
                const url = `v2/timetable/${timetableId}/info`
                const res = await axios.get(url)
                if (res && res.data && res.data.status === 'ok') {
                    state.timetable = {
                        id: timetableId,
                        info: res.data.data
                    }
                }
                commit('setLoading', false)
            }
        }
    },
    modules: {
        lineLiff: {
            namespaced: true,
            state: {
                title: {
                    label: '',
                    path: ''
                },
                isError: ''
            },
            mutations: {
                setTitle(state, title) {
                    state.title = title
                },
                setError(state, error) {
                    state.isError = error
                },
            }
        }
    }



})
