const DAYSTH = ['วันอาทิตย์', 'วันจันทร์', 'วันอังคาร', 'วันพุธ', 'วันพฤหัสบดี', 'วันศุกร์', 'วันเสาร์']
const DAYSEN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const SHORTDAYSTH = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.']
const SHORTDAYSEN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const MONTHSTH = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
const MONTHSEN = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const SHORTMONTHSTH = ['ม.ค.', 'ก.พ', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค']
const SHORTMONTHSEN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default {
    months (currentLang) {
        let MONTHS = MONTHSEN
        if (currentLang === 'th') {
            MONTHS = MONTHSTH
        }
        return MONTHS
    },

    dayList (currentLang, cutPrefix = false) {
        if (currentLang === 'th') {
            return cutPrefix ? DAYSTH.map(d => d.slice(3)) : DAYSTH
        }
        return DAYSEN
    },

    getDay (weekDay, currentLang, cutPrefix = false) {
        if (!weekDay) {
            return null
        }
        weekDay -= 1
        if (currentLang === 'th') {
            return cutPrefix ? DAYSTH[weekDay].slice(3) : DAYSTH[weekDay]
        }
        return DAYSEN[weekDay]
    },

    monthList (currentLang) {
        const data = []
        let MONTHS = MONTHSEN
        if (currentLang === 'th') {
            MONTHS = MONTHSTH
        }
        for (let i = 0; i < MONTHS.length; i++) {
            data.push({
                text: MONTHS[i],
                value: i
            })
        }
        return data
    },

    formatDate (date) {
        if (!date) {
            return null
        }

        const [year, month, day] = date.split('-')
        const BEYear = parseInt(year) + 543
        return `${day}/${month}/${BEYear}`
    },

    formatDateMonth (date) {
        if (!date) {
            return null
        }

        const dateVal = date.split('-')
        return `${dateVal[2]}/${dateVal[1]}`
    },

    getDateMonthByDateString (date) {
        if (!date) {
            return null
        }

        const dateVal = date.split('-')
        return {
            date: parseInt(dateVal[2]),
            month: parseInt(dateVal[1])
        }
    },

    formatTime (time) {
        return time.slice(0, 5)
    },

    dateToDateString (date, currentLang) {
        if (!date) {
            return null
        }
        let MONTHS = MONTHSEN
        let DAYS = DAYSEN
        if (currentLang === 'th') {
            DAYS = DAYSTH
            MONTHS = MONTHSTH
        }
        const day = date.getDay()

        const dayString = DAYS[day]

        const month = date.getMonth()
        const monthString = MONTHS[month]

        const year = date.getFullYear() + 543

        const dateNumber = date.getDate()

        return `${dayString} ${dateNumber} ${monthString} ${year}`
    },

    getFullMonth (month, currentLang) {
        const MONTHS = month(currentLang)

        if (MONTHS[month]) {
            return MONTHS[month]
        }

        return '-'
    },

    dateStringToDateObj (dateString) {
        if (!dateString) {
            return null
        }

        const dateVal = dateString.split('/')
        const year = parseInt(dateVal[2]) - 543
        const month = parseInt(dateVal[1])
        const date = parseInt(dateVal[0])

        const dateObj = new Date()
        dateObj.setFullYear(year)
        dateObj.setDate(date)
        dateObj.setMonth(month - 1)
        dateObj.setHours(0)
        dateObj.setMinutes(0)
        dateObj.setSeconds(0)

        return dateObj
    },

    timeStringToMinute (v) {
        if (!v) {
            return 0
        }

        const [hourStr, minuteStr] = v.split(':')
        return (parseInt(hourStr, 10) * 60) + parseInt(minuteStr, 10)
    },

    formatDateData (date, format) {
        if (format === 1) {
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year.slice(0, 2)}`
        }
    },

    formatDateBE (date, format) {
        const [year, month, day] = date.split('-')
        const BEYear = parseInt(year) + 543
        if (format === 1) {
            return `${day}/${month}/${BEYear.toString().slice(2, 4)}` // short year
        } else if (format === 2) {
            return `${day}/${month}/${BEYear}` // long year
        }
    },

    dateBEStringToDateObj (dateString) {
        if (!dateString) {
            return null
        }

        const dateVal = dateString.split('/')
        if (dateVal[2].length === 2) {
            dateVal[2] = parseInt(dateVal[2]) + 2500
        }
        dateString = `${dateVal[0]}/${dateVal[1]}/${dateVal[2]}`
        return this.dateStringToDateObj(dateString)
    },

    formatSerialDateTime (dateTime) {
        if (!dateTime) {
            return null
        }
        const year = dateTime.getFullYear()
        const month = (dateTime.getMonth() + 1).toString().replace(/^(\d)$/, '0$1')
        const day = dateTime.getDate().toString().replace(/^(\d)$/, '0$1')
        const hour = dateTime.getHours().toString().replace(/^(\d)$/, '0$1')
        const minute = dateTime.getMinutes().toString().replace(/^(\d)$/, '0$1')
        const second = dateTime.getSeconds().toString().replace(/^(\d)$/, '0$1')
        return `${year}${month}${day}${hour}${minute}${second}`
    },

    getCurrentDate () {
        const now = new Date()
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset())
        return now.toISOString().substring(0, 10)
    },

    dateToShortDateString (dateInput, currentLang) {
        const date = new Date(dateInput)
        if (!date) {
            return null
        }
        let MONTHS = SHORTMONTHSEN
        if (currentLang === 'th') {
            MONTHS = SHORTMONTHSTH
        }
        const month = date.getMonth()
        const monthString = MONTHS[month]

        let year = date.getFullYear()

        if (currentLang === 'th') {
            year += 543
        }

        const yearStr = year.toString()
        const shortYear = yearStr.slice(-2)

        const dateNumber = date.getDate()

        return `${dateNumber} ${monthString} ${shortYear}`
    },

    yearThToEnString (yearInput, currentLang) {
        let year = parseInt(yearInput)
        if (currentLang === 'en') {
            year -= 543
        }
        const yearStr = year.toString()
        return `${yearStr}`
    },
    weekDayToString (weekDay, currentLang) {
        if (currentLang === 'th') {
            return DAYSTH[weekDay - 1]
        } else {
            return DAYSEN[weekDay - 1]
        }
    },
    weekDayToShortString (weekDay, currentLang) {
        if (currentLang === 'th') {
            return SHORTDAYSTH[weekDay - 1]
        } else {
            return SHORTDAYSEN[weekDay - 1]
        }
    }

}
