export default {

    modules: [
        { primKey: 'programId', name: 'CurriculumProgramPage', value: 1, redirect: 'CurriculumList', from: 'CurriculumProgramList' },
        { primKey: 'timetableId', name: 'ClassroomPage', value: 2, redirect: 'TimetableList', from: 'TimetablePage' },
        { primKey: 'timetableId', name: 'SimultaneousPage', value: 3, redirect: 'TimetableList', from: 'TimetablePage' },
        { primKey: 'timetableId', name: 'InstructorPage', value: 4, redirect: 'TimetableList', from: 'InstructorDepartmentList', secKey: 'departmentId' },
        { primKey: 'timetableId', name: 'TimetableStudentPage', value: 5, redirect: 'TimetableList', from: 'TimetableScheduleClassroomList', secKey: 'classroomId' },
        { primKey: 'timetableId', name: 'TimetableScheduleInstructorPage', value: 6, redirect: 'TimetableList', from: 'TimetableScheduleInstructorList', secKey: 'teacherId' },
        { primKey: 'timetableId', name: 'TeacherCourseTypeByPassableInfoPage', value: 7, redirect: 'TeacherCourseListAcademicRecordInfoPage', from: 'TeacherCourseListAcademicRecordInfoPage', secKey: 'courseId' },
        { primKey: 'timetableId', name: 'TeacherCourseTypeByLetterInfoPage', value: 8, redirect: 'TeacherCourseListAcademicRecordInfoPage', from: 'TeacherCourseListAcademicRecordInfoPage', secKey: 'courseId' }
    ]
}