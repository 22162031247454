<template>
    <div class="navbarLineliff">
        <div class="d-flex align-center">
            <v-icon
                v-if="getTitle.path"
                @click="go(getTitle.path)"
                class="mr-1"
                color="white"
            >
                mdi-chevron-left
            </v-icon>
            <div class="text-title-line">{{ getTitle.title }}</div>
        </div>
        <div class="ml-auto">
            <lang-selector />
        </div>
    </div>
</template>

<script>
import LangSelector from "@/components/LangSelector";
export default {
    name: "NavbarLineLiff",
    components: {
        LangSelector,
    },
    computed: {
        getTitle() {
            return this.$store.state.lineLiff.title;
        },
    },
    methods: {
        go(route) {
            if (route) {
                this.$router.push(route);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.navbarLineliff {
    height: 80px;
    padding-block: 24px;
    padding-inline: 14px;
    color: $white;
    display: flex;
    align-items: center;
    .d-flex {
        margin-left: 10px;
        .v-icon {
            cursor: pointer;
        }
    }
    .lang-selector {
        color: $white;
    }
}
.text-title-line {
    font-weight: 400;
    font-size: 18px;
}
</style>
