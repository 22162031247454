export default {
    delete: 'ลบข้อมูล',
    cancel: 'ยกเลิก',
    apply: 'ยืนยัน',
    headerText: 'ต้องการลบกลุ่มสาระ ?',
    headerCourse: 'ต้องการลบวิชา ?',
    firstContentText: 'วิชาในกลุ่มสาระ ',
    lastContentText: 'จะถูกลบทั้งหมด',
    errorMessage: 'ERROR',
    warningModal: 'ต้องการลบวิชา'
}
