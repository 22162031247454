export default {
    header: 'จำนวนสัปดาห์การสอน',
    label: {
        totalWeek: 'จำนวนสัปดาห์'
    },
    suffix: {
        totalWeek: 'สัปดาห์'
    },
    rules: {
        totalWeek: [
            'ต้องมากกว่า 0'
        ]
    }
}