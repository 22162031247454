export default {
    curriculum: 'จัดการหลักสูตร',
    searchplaceholder: 'ค้นหาโดย ปีการศึกษา',
    search: 'ค้นหา',
    syllabus: 'หลักสูตร',
    addSyllabus: 'กรุณาเพิ่มหลักสูตร',
    cancel: 'ยกเลิก',
    apply: 'ยืนยัน',
    schoolYear: 'ปีการศึกษา',
    studyOfPlans: 'จำนวนแผนการเรียน',
    status: 'สถานะ',
    upDate: 'อัปเดตล่าสุด',
    createBy: 'สร้างโดย',
    btnHeaderDelete: 'ต้องการลบหลักสูตร ?',
    btnContentDelete: 'ปีการศึกษา',
    btnDetailDelete: 'ข้อมูลหลักสูตรในปีการศึกษาจะถูกลบทั้งหมด',
    draft: 'ฉบับร่าง',
    inactive: 'ใช้งานแล้ว',
    active: 'กำลังใช้งาน',
    complete: 'สำเร็จ',
    notify: {
        title: 'ลบหลักสูตร',
        success: 'สำเร็จ'
    }
}
