export default {
    anotherUser: 'ผู้ใช้งานอื่นกำลังแก้ไขข้อมูล',
    timeOut: 'หมดเวลาการแก้ไข',
    firstUserTextContent: 'กำลังใช้งานหน้านี้ คุณไม่สามารถ',
    secondUserTextContent: 'แก้ไขข้อมูลได้ จนกว่าผู้ใช้งานอื่นจะใช้งานสำเร็จ',
    firstTimeOutTextContent: 'ไม่พบการใช้งาน เป็นเวลา 30 นาที',
    secondTimeOutTextContent: 'เพื่อความปลอดภัย หน้าที่ไม่ถูกใช้งานจะถูกปิด',
    thirdTimeOutTextContent: 'โดยอัตโนมัติ กรุณาเริ่มแก้ไขข้อมูลใหม่อีกครั้ง',
    curriculumManagement: 'จัดการหลักสูตร',
    academicYear: 'ปีการศึกษา',
    save: 'บันทึก',
    saveDraft: 'บันทึกฉบับร่าง',
    header: 'วิชาเรียน',
    semester1: 'ภาคเรียนที่ 1',
    semester2: 'ภาคเรียนที่ 2',
    semester3: 'ภาคเรียนที่ 3',
    semester4: 'ภาคเรียนที่ 4',
    semester5: 'ซัมเมอร์',
    saveDraftSuccess: 'บันทึกร่างสำเร็จ',
    saveSuccess: 'บันทึกร่างสำเร็จ',
    saveError: 'การบันทึกผิดพลาดไม่สามารถบันทึกได้'
}
