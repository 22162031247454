export default{
    overview:'ภาพรวม',
    title:'ภาพรวมการลงทะเบียน',
    primarySchool:'ประถมศึกษา',
    secondarySchool:'มัธยมศึกษา',
    searchPlaceHolder:'ค้นหาโดย รหัสนักเรียน / รายชื่อนักเรียน',
    search:'ค้นหา',
    levelLabel:"ระดับชั้นทั้งหมด",
    statusLabel:"สถานะทั้งหมด",
    summer:'ซัมเมอร์',
    studentNo:'รหัสนักเรียน',
    name:'ชื่อ - นามสกุล',
    level:'ระดับชั้น',
    room:'ห้องเรียน',
    registered:'จำนวนวิชา',
    status:'สถานะลงทะเบียน',
    unsuccess:'ไม่สำเร็จ',
    success:'สำเร็จ',
    year:'ปีการศึกษา',
    totalStudent:'นักเรียนทั้งหมด',
    registeredCount:'ลงทะเบียนครบ',
    unregisteredCount:'ลงทะเบียนไม่ครบ',
    notFound:'ไม่พบข้อมูล'
}