export default {
    studentInfo:'ข้อมูลนักเรียน',
    studentNo:'รหัสนักเรียน',
    name:'ชื่อ - นามสกุล',
    room:'ห้องเรียน',
    courseCount:'จำนวนวิชา',
    statusRegister:'สถานะลงทะเบียน',
    year:'ปีการศึกษา',
    summer:'ซัมเมอร์',
    courseInfo:'ข้อมูลรายวิชา',
    semester:'ภาคการศึกษา',
    courseNo:'รหัสวิชา',
    courseName:'ชื่อวิชา',
    type:'ประเภท',
    hourPerYear:'ชม./ปี',
    hourPerSemester:'ชม./ภาคเรียน',
    totalPeriod:'จำนวนคาบ',
    teacher:'ครูผู้สอน',
    success:'สำเร็จ',
    unsuccess:'ไม่สำเร็จ',
    totalCourse:'จำนวนวิชาทั้งหมด',
    courseBasic:'รายวิชาพื้นฐาน',
    courseAdditional:'รายวิชาเพิ่มเติม',
    totalActivity:'กิจกรรม',
    notRegister:'ยังไม่ลงทะเบียน',
    course:'วิชา',
    PeriodPerWeek:'คาบ / สัปดาห์',
    studySummary:'รวมเวลาเรียนทั้งสิ้น',
    courseType: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    }
}