export default {
    room: 'ห้อง',
    courseNo: 'รหัสวิชา',
    courseName: 'ชื่อวิชา',
    recordDate: 'รอบปกติ',
    specialDate: 'รอบพิเศษ',
    updatedAt: 'อัปเดตล่าสุด',
    search: 'ค้นหา',
    searchPlaceHolder: 'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    teacher: 'ครูผู้สอน'
}