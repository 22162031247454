export default {
    placeHold: 'ค้นหาโดย ปีการศึกษา',
    search: 'ค้นหา',
    numberChoose: 'จำนวนวิชาเลือก',
    semesterPlaceholder: 'ภาคเรียนทั้งหมด',
    year: 'ปีการศึกษา',
    semester: 'ภาคเรียน',
    totalTeacher:'จำนวนครู',
    regisSuccess:'นักเรียนลงทะเบียนสำเร็จ',
    allSemester:'ภาคการศึกษาทั้งหมด',
    summerSemester:'Summer',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    status: 'สถานะ',
    active: 'Active',
    inactive:'ยังไม่เปิดใช้งาน',
    pending:'รอการตั้งค่าบันทึกผล',
    registerDate:'วันลงทะเบียน',
    deactivated:"ปิดการใช้งาน",
    tooltipYear:'ระบบจะแสดงรายการให้ โดยอัตโนมัติ โดยอ้างอิงปีการศึกษา และภาคเรียน ที่ตั้งค่าการบันทึกผลการเรียน สำเร็จแล้ว',
    tooltipDate:'วันเปิดภาคเรียน ถึง วันเริ่มบันทึกผล',
    emptyTable1:'กรุณาตั้งค่าบันทึกผลการเรียนให้สำเร็จก่อน',
    emptyTable2:'ระบบจะแสดงรายการให้โดยอัตโนมัติ',
    notFound:'ไม่พบข้อมูล'
}
