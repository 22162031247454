import { en } from 'vuetify/lib/locale'

import sideBar from '@/lang/components/SideBar-en'
import EditWarningModal from '@/lang/components/modal/EditWarningModal-en'
import ModalConfirmLeavePage from './components/modal/ModalConfirmLeavePage-en'

import deleteModal from '@/lang/components/modal/DeleteModal-en'
import departmentForm from '@/lang/components/modal/DepartmentForm-en'
import departmentFormModal from '@/lang/components/departments/DepartmentForm-en'
import CourseFormModal from '@/lang/components/modal/CourseFormModal-en'
import departmentList from '@/lang/view/department/DepartmentList-en'
import departmentInfo from '@/lang/view/department/DepartmentInfo-en'

import modalAddCurriculum from '@/lang/components/modal/AddModalCurriculum-en'
import curriculumProgramPage from '@/lang//view/curriculum/CurriculumProgramPage-en'
import curriculumProgramList from '@/lang/view/curriculum/CurriculumProgramList-en'
import curriculumCourseList from '@/lang/components/curriculum/CurriculumCourseList-en'
import curriculumProgramInfo from './components/curriculum/CurriculumProgramInfo-en'
import curriculumList from '@/lang/view/curriculum/CurriculumList-en'

import timetableManagement from './view/timetable/TimetableManagement-en'
import timetableTemplateList from './components/timetable/TimetableTemplateList-en'
import timetableScheduleFormModal from './components/timetable/TimetableScheduleFormModal-en'
import timetableList from './components/timetable/TimetableList-en'
import timetablePage from './view/timetable/TimetablePage-en'
import timetableCardInfo from './components/timetable/TimetableCardInfo-en'
import TimetableTotalWeekFormModal from './components/modal/TimetableTotalWeekFormModal-en'
import timetableCardIndex from './components/timetable/TimetableCardIndex-en'
import timetableCardInstructor from './components/timetable/TimetableCardInstructor-en'
import timetableTemplate from './view/timetable/TimetableTemplate-en'
import timetableTemplateTimeDetail from './view/timetable/TimetableTemplateTimeDetail-en'
import timetableTemplateDetail from './view/timetable/TimetableTemplateDetail-en'

import timetableInstructorDepartmentList from './view/timetable/InstructorDepartmentList-en'
import timetableInstructorPage from './view/timetable/InstructorPage-en'
// import timetableClassroomPage from './components/timetable/TimetableClassroomPage-en'

import timetableClassroomPage from './view/timetable/TimetableClassroomPage-en'
import timetableScheduleClassroomList from './view/timetable/TimetableScheduleClassroomList-en'
import timetableScheduleInstructorList from './view/timetable/TimetableScheduleInstructorList-en'
import timetableScheduleInstructorInfo from './components/timetable/TimetableScheduleInstructorInfo-en'
import timetableScheduleInstructorFormModal from './components/timetable/TimetableScheduleInstructorFormModal-en'
import timetableScheduleInstructor from './components/timetable/TimetableScheduleInstructor-th'
import simultaneousPage from './view/timetable/SimultaneousPage-en'
import simultaneousForm from './components/timetable/SimultaneousForm-en'
import confirmMergeSimultaneous from './components/modal/ConfirmMergeSimultaneous-en'
import timetableStudentPage from './view/timetable/TimetableStudentPage-en'
import timetableAssignInstructor from './view/timetable/TimetableAssignInstructor-en'
import timetableScheduleInstructorDownload from './view/timetable/TimetableScheduleInstructorDownload-en'
import timetableTagConditionPage from './view/timetable/TimetableTagConditionPage-en'
import timetableTagCondition from './components/timetable/TimetableTagCondition-en'
import timetableTemplatePreview from './components/modal/TimetableTemplatePreview-en'

import teacherAcademicRecordListPage from './view/instructorScoring/TeacherAcademicRecordListPage-en'
import teacherAcademicRecordList from './components/instructorScoring/TeacherAcademicRecordList-en'
import teacherCourseListAcademicRecordInfo from './components/instructorScoring/TeacherCourseListAcademicRecordInfo-en'
import teacherAcademicRecordCourseInfo from './components/instructorScoring/TeacherAcademicRecordCourseInfo-en'
import teacherCourseTypeByPassable from './components/instructorScoring/TeacherCourseTypeByPassable-en'
import teacherCourseTypeByLetterInfo from './components/instructorScoring/TeacherCourseTypeByLetterInfo-en'
import teacherCourseByLetterSettingModal from './components/modal/TeacherCourseByLetterSettingModal-en'
import teacherCourseByLetterRecordTable from './components/instructorScoring/TeacherCourseByLetterRecordTable-en'

import electiveCourses from './view/electivecourses/ElectiveCourses-en'
import electiveTableList from './components/electivecourses/ElectivetableList-en'
import electiveCourseInfoPage from './view/electivecourses/ElectiveCourseInfoPage-en'
import electiveCourseInfo from './components/electivecourses/ElectiveCourseInfo-en'
import courseListOfElectiveCourse from './components/electivecourses/CourseListOfElectiveCourse-en'
import courseInfoPage from './view/electivecourses/CourseInfoPage-en'
import addStudentForCourse from './components/electivecourses/AddStudentForCourse-en'
import electiveCourseOverviewsPage from './view/electivecourses/ElectiveCourseOverviewsPage-en'
import studentElectiveCourseInfoPage from './view/electivecourses/StudentElectiveCourseInfoPage-en'

import electiveCourseSettingPage from './view/electiveCoursesSetting/ElectiveCourseSettingPage-en'

import studentAttendanceManagementLiffPage from './view/absentRecordLineLiff/StudentAttendanceManagementLiffPage-en'
import studentAttendanceListByCourseLineLiffPage from './view/absentRecordLineLiff/StudentAttendanceListByCourseLineLiffPage-en'
import academicSettingInfo from './components/instructorAbsentRecording/AcademicSettingInfo-en'
import academicYearList from './components/instructorAbsentRecording/AcademicYearList-en'
import studentCourseList from './components/instructorAbsentRecording/StudentCourseList-en'
import courseInfo from './components/instructorAbsentRecording/CourseInfo-en'
import studentAttendanceListByCourse from './components/instructorAbsentRecording/StudentAttendanceListByCourse-en'
import modalAttendanceManagement from './components/modal/ModalAttendanceManagement-en'

import teacherScheduleListPage from './view/instructorTimetable/TeacherScheduleListPage-en'
import teacherCoursesInfo from './components/instructorTimetable/TeacherCoursesInfo-en'
import teacherCoursesList from './components/instructorTimetable/TeacherCoursesList-en'
export default {
    ...en,
    sideBar,
    EditWarningModal,
    deleteModal,
    departmentForm,
    departmentList,
    departmentFormModal,
    CourseFormModal,
    departmentInfo,
    curriculumList,
    modalAddCurriculum,
    curriculumProgramPage,
    curriculumProgramList,
    curriculumCourseList,
    curriculumProgramInfo,
    timetablePage,
    timetableCardInfo,
    TimetableTotalWeekFormModal,
    timetableTemplate,
    timetableTemplateTimeDetail,
    timetableTemplateDetail,
    timetableManagement,
    timetableTemplateList,
    timetableScheduleFormModal,
    timetableList,
    timetableInstructorDepartmentList,
    timetableInstructorPage,
    timetableCardIndex,
    timetableCardInstructor,
    timetableClassroomPage,
    timetableScheduleInstructorDownload,
    timetableTagConditionPage,
    timetableTagCondition,

    electiveCourses,
    electiveTableList,
    electiveCourseInfoPage,
    electiveCourseInfo,
    courseListOfElectiveCourse,
    courseInfoPage,
    addStudentForCourse,
    electiveCourseOverviewsPage,
    studentElectiveCourseInfoPage,
    electiveCourseSettingPage,

    timetableStudentPage,
    ModalConfirmLeavePage,
    timetableScheduleClassroomList,
    timetableScheduleInstructorList,
    timetableScheduleInstructorInfo,
    timetableScheduleInstructorFormModal,
    timetableScheduleInstructor,
    simultaneousPage,
    simultaneousForm,
    confirmMergeSimultaneous,
    timetableAssignInstructor,
    timetableTemplatePreview,

    teacherAcademicRecordListPage,
    teacherAcademicRecordList,
    teacherCourseListAcademicRecordInfo,
    teacherAcademicRecordCourseInfo,
    teacherCourseTypeByPassable,
    teacherCourseTypeByLetterInfo,
    teacherCourseByLetterSettingModal,
    teacherCourseByLetterRecordTable,

    studentAttendanceManagementLiffPage,
    studentAttendanceListByCourseLineLiffPage,
    academicSettingInfo,
    academicYearList,
    studentCourseList,
    courseInfo,
    studentAttendanceListByCourse,
    modalAttendanceManagement,

    teacherScheduleListPage,
    teacherCoursesInfo,
    teacherCoursesList
}
