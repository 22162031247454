export default {
    title:'บันทึกผลขาดเรียน',
    search:'ค้นหา',
    searchPlaceHolder:'ค้นหาโดย รหัสนักเรียน / ชื่อนักเรียน',
    allComplete:'บันทึกผลผ่านทั้งหมด',
    classroom:"ห้อง",
    indexNo:'เลขที่',
    studentNo:'รหัสนร.',
    fullName:'ชื่อ - นามสกุล',
    attendPct:'เข้าเรียน',
    totalAbsPeriod:'ขาดเรียน (คาบ)',
    updatedDate:'อัปเดตล่าสุด',
    saveSuccess:'บันทึกสำเร็จ',
    courses:'รายวิชา',
    absenceReport: 'รายงานขาดเรียน'
}