import { th } from 'vuetify/lib/locale'

import sideBar from '@/lang/components/SideBar-th'
import EditWarningModal from '@/lang/components/modal/EditWarningModal-th'
import deleteModal from '@/lang/components/modal/DeleteModal-th'
import ModalConfirmLeavePage from './components/modal/ModalConfirmLeavePage-th'

import departmentForm from '@/lang/components/modal/DepartmentForm-th'
import departmentList from '@/lang/view/department/DepartmentList-th'
import departmentFormModal from '@/lang/components/departments/DepartmentForm-th'

import CourseFormModal from '@/lang/components/modal/CourseFormModal-th'
import departmentInfo from '@/lang/view/department/DepartmentInfo-th'
import curriculumList from '@/lang/view/curriculum/CurriculumList-th'
import modalAddCurriculum from '@/lang/components/modal/AddModalCurriculum-th'
import curriculumProgramPage from '@/lang//view/curriculum/CurriculumProgramPage-th'
import curriculumProgramList from '@/lang/view/curriculum/CurriculumProgramList-th'
import curriculumCourseList from '@/lang/components/curriculum/CurriculumCourseList-th'
import curriculumProgramInfo from './components/curriculum/CurriculumProgramInfo-th'
import timetableManagement from './view/timetable/TimetableManagement-th'
import timetableTemplateList from './components/timetable/TimetableTemplateList-th'
import timetableScheduleFormModal from './components/timetable/TimetableScheduleFormModal-th'

import timetableList from './components/timetable/TimetableList-th'
import timetablePage from './view/timetable/TimetablePage-th'
import timetableCardInfo from './components/timetable/TimetableCardInfo-th'
import TimetableTotalWeekFormModal from './components/modal/TimetableTotalWeekFormModal-th'
import timetableCardIndex from './components/timetable/TimetableCardIndex-th'
import timetableCardInstructor from './components/timetable/TimetableCardInstructor-th'

import timetableTemplate from './view/timetable/TimetableTemplate-th'
import timetableTemplateTimeDetail from './view/timetable/TimetableTemplateTimeDetail-th'
import timetableTemplateDetail from './view/timetable/TimetableTemplateDetail-th'

import timetableInstructorDepartmentList from './view/timetable/InstructorDepartmentList-th'
import timetableInstructorPage from './view/timetable/InstructorPage-th'
// import timetableClassroomPage from './components/timetable/TimetableClassroomPage-th'

import timetableClassroomPage from './view/timetable/TimetableClassroomPage-th'
import timetableScheduleClassroomList from './view/timetable/TimetableScheduleClassroomList-th'
import timetableScheduleInstructorList from './view/timetable/TimetableScheduleInstructorList-th'
import timetableScheduleInstructorInfo from './components/timetable/TimetableScheduleInstructorInfo-th'
import timetableScheduleInstructorFormModal from './components/timetable/TimetableScheduleInstructorFormModal-th'
import timetableScheduleInstructor from './components/timetable/TimetableScheduleInstructor-th'
import simultaneousPage from './view/timetable/SimultaneousPage-th'
import simultaneousForm from './components/timetable/SimultaneousForm-th'
import confirmMergeSimultaneous from './components/modal/ConfirmMergeSimultaneous-th'
import timetableStudentPage from './view/timetable/TimetableStudentPage-th'
import timetableAssignInstructor from './view/timetable/TimetableAssignInstructor-th'
import timetableScheduleInstructorDownload from './view/timetable/TimetableScheduleInstructorDownload-th'
import timetableTagConditionPage from './view/timetable/TimetableTagConditionPage-th'
import timetableTagCondition from './components/timetable/TimetableTagCondition-th'
import timetableTemplatePreview from './components/modal/TimetableTemplatePreview-th'

import electiveCourses from './view/electivecourses/ElectiveCourses-th'
import electiveTableList from './components/electivecourses/ElectivetableList-th'
import electiveCourseInfoPage from './view/electivecourses/ElectiveCourseInfoPage-th'
import electiveCourseInfo from './components/electivecourses/ElectiveCourseInfo-th'
import courseListOfElectiveCourse from './components/electivecourses/CourseListOfElectiveCourse-th'
import courseInfoPage from './view/electivecourses/CourseInfoPage-th'
import addStudentForCourse from './components/electivecourses/AddStudentForCourse-th'
import electiveCourseOverviewsPage from './view/electivecourses/ElectiveCourseOverviewsPage-th'
import studentElectiveCourseInfoPage from './view/electivecourses/StudentElectiveCourseInfoPage-th'

import electiveCourseSettingPage from './view/electiveCoursesSetting/ElectiveCourseSettingPage-th'
import teacherAcademicRecordListPage from './view/instructorScoring/TeacherAcademicRecordListPage-th'
import teacherAcademicRecordList from './components/instructorScoring/TeacherAcademicRecordList-th'
import teacherCourseListAcademicRecordInfo from './components/instructorScoring/TeacherCourseListAcademicRecordInfo-th'
import teacherAcademicRecordCourseInfo from './components/instructorScoring/TeacherAcademicRecordCourseInfo-th'
import teacherCourseTypeByPassable from './components/instructorScoring/TeacherCourseTypeByPassable-th'
import teacherCourseTypeByLetterInfo from './components/instructorScoring/TeacherCourseTypeByLetterInfo-th'
import teacherCourseByLetterSettingModal from './components/modal/TeacherCourseByLetterSettingModal-th'
import teacherCourseByLetterRecordTable from './components/instructorScoring/TeacherCourseByLetterRecordTable-th'
import studentAttendanceManagementLiffPage from './view/absentRecordLineLiff/StudentAttendanceManagementLiffPage-th'
import studentAttendanceListByCourseLineLiffPage from './view/absentRecordLineLiff/StudentAttendanceListByCourseLineLiffPage-th'
import academicSettingInfo from './components/instructorAbsentRecording/AcademicSettingInfo-th'
import academicYearList from './components/instructorAbsentRecording/AcademicYearList-th'
import studentCourseList from './components/instructorAbsentRecording/StudentCourseList-th'
import courseInfo from './components/instructorAbsentRecording/CourseInfo-th'
import studentAttendanceListByCourse from './components/instructorAbsentRecording/StudentAttendanceListByCourse-th'
import modalAttendanceManagement from './components/modal/ModalAttendanceManagement-th'
import teacherScheduleListPage from './view/instructorTimetable/TeacherScheduleListPage-th'
import teacherCoursesInfo from './components/instructorTimetable/TeacherCoursesInfo-th'
import teacherCoursesList from './components/instructorTimetable/TeacherCoursesList-th'


export default {
    ...th,
    sideBar,
    EditWarningModal,
    deleteModal,
    departmentForm,
    departmentList,
    departmentFormModal,
    CourseFormModal,
    departmentInfo,
    curriculumList,
    modalAddCurriculum,
    curriculumProgramPage,
    curriculumProgramList,
    curriculumCourseList,
    curriculumProgramInfo,
    timetablePage,
    timetableCardInfo,
    TimetableTotalWeekFormModal,
    timetableTemplate,
    timetableTemplateTimeDetail,
    timetableTemplateDetail,
    timetableManagement,
    timetableTemplateList,
    timetableScheduleFormModal,
    timetableList,
    timetableCardIndex,
    timetableCardInstructor,
    timetableClassroomPage,
    timetableScheduleInstructorDownload,
    timetableTagConditionPage,
    timetableTagCondition,
    timetableTemplatePreview,
    electiveCourses,
    electiveTableList,
    electiveCourseInfoPage,
    electiveCourseInfo,
    courseListOfElectiveCourse,
    courseInfoPage,
    addStudentForCourse,
    electiveCourseOverviewsPage,
    studentElectiveCourseInfoPage,
    electiveCourseSettingPage,
    timetableStudentPage,
    ModalConfirmLeavePage,
    timetableScheduleClassroomList,
    timetableScheduleInstructorList,
    timetableScheduleInstructorInfo,
    timetableScheduleInstructorFormModal,
    timetableScheduleInstructor,
    simultaneousPage,
    simultaneousForm,
    confirmMergeSimultaneous,
    timetableAssignInstructor,
    timetableInstructorDepartmentList,
    timetableInstructorPage,
    teacherAcademicRecordListPage,
    teacherAcademicRecordList,
    teacherCourseListAcademicRecordInfo,
    teacherAcademicRecordCourseInfo,
    teacherCourseTypeByPassable,
    teacherCourseTypeByLetterInfo,
    teacherCourseByLetterSettingModal,
    teacherCourseByLetterRecordTable,
    studentAttendanceManagementLiffPage,
    studentAttendanceListByCourseLineLiffPage,
    academicSettingInfo,
    academicYearList,
    studentCourseList,
    courseInfo,
    studentAttendanceListByCourse,
    modalAttendanceManagement,
    teacherScheduleListPage,
    teacherCoursesInfo,
    teacherCoursesList
}
