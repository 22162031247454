export default {
    title: 'บันทึกผลการเรียน',
    detail: 'ตารางบันทึกผลการเรียน จะแสดงหลังจากกำหนดตารางบันทึกสำเร็จ',
    room: 'ห้อง',
    secMember: 'เลขที่',
    studentNo: 'รหัสนักเรียน',
    fullName: 'ชื่อ-นามสกุล',
    total: 'คะแนนเต็ม',
    assignment: 'ใบงาน',
    noAssignment: 'ตั้งค่า “จัดการตาราง”',
    exam: 'คะแนนสอบ',
    midExam: 'สอบกลางภาค',
    finExam: 'สอบปลายภาค',
    accPct: 'คะแนนเก็บร้อยละ',
    bfMidAcc: 'ก่อนกลางภาค',
    afMidAcc: 'หลังกลางภาค',
    examPct: 'คะแนนสอบร้อยละ',
    allPct: 'รวมคะแนนร้อยละ',
    RTAW: 'อ่าน คิด วิเคราะห์ และเขียน',
    tooltip: "ผู้สอนต้องสรุปผลคะแนนเฉลี่ยก่อนระบุในช่องคะแนน",
    affection: 'คุณลักษณะอันพึงประสงค์',
    grade: 'ปกติ (เกรด)',
    gradeTooltip: 'กรุณาใส่คะแนนเก็บ และคะแนนสอบให้ครบทุกช่อง เพื่อให้ระบบคำนวณเกรด',
    specialGrade: 'แก้ตัว',
    note: 'หมายเหตุ (ถ้ามี)',
    noteTooltip: 'ระบุได้ 30 ตัวอักษร หมายเหตุนี้จะแสดงบนใบเกรด',
    attendPct: 'เข้าเรียน',
    updatedDate: 'อัปเดตล่าสุด',
    updatedBy: 'โดย',
    search: 'ค้นหา',
    searchPlaceHolder: 'ค้นหาโดย รหัสนักเรียน / ชื่อนักเรียน',
    gradeNumType: {
        null: '-',
        INCOMPLETE: 'ร',
        F: '0',
        DTE: 'มส',
        A: '4',
        B_PLUS: '3.5',
        B: '3',
        C_PLUS: '2.5',
        C: '2',
        D_PLUS: '1.5',
        D: '1'
    },
    gradeCharType: {
        null: '-',
        INCOMPLETE: 'ร',
        F: 'F',
        DTE: 'มส',
        A: 'A',
        B_PLUS: 'B+',
        B: 'B',
        C_PLUS: 'C+',
        C: 'C',
        D_PLUS: 'D+',
        D: 'D'
    },
    person: 'คน',
    detailF: 'ติด 0',
    notifyError: {
        title: 'ข้อมูลคะแนนผิดพลาด:',
        list: 'รายการ',
        content: 'กรุณาเลื่อนตารางเพื่อดูรายชื่อที่ผิดพลาด'
    }
}