export default {
    title: 'รายการวิชาเลือก',
    code: 'รหัสวิชา',
    name: 'ชื่อวิชา',
    type: 'ประเภท',
    level: 'ระดับชั้น',
    registered: 'ลงทะเบียน',
    typePlaceholder: 'ประเภททั้งหมด',
    levelPlaceholder: 'ระดับชั้นทั้งหมด',
    secondarySchool: 'มัธยมศึกษา',
    primarySchool: 'ประถมศึกษา',
    search: 'ค้นหา',
    searchPlaceHolder: 'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    firstTextEmpty:'ไม่พบรายวิชาที่ต้องสอน',
    courseType: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    },
    searchAcademicYearPlaceHolder: 'ค้นหาโดย ปีการศึกษา',
    notFound:'ไม่พบข้อมูล'
}

