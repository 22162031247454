export default {
    title: 'สารบัญ',
    add: 'เพิ่ม',
    dummy: [
        'Dummy',
        'คน'
    ],
    modal: {
        title: 'ต้องการลบสารบัญ ?',
        content: 'จะถูกล้างข้อมูล และต้องตั้งค่าใหม่ทั้งหมด'
    },
    editStateNotSelectableFistSection: 'จะถูกล้างข้อมูล และต้องตั้งค่าผู้สอน',
    editStateNotSelectableSecondSection: 'ในขั้นตอนที่ 3 อีกครั้ง',
    editStateSelectableFistSection: 'เป็นวิชาเลือก จำเป็นต้องตั้งค่าไว้ หากทำการลบ',
    editStateSelectableSecondSection: 'ระบบจะไม่อนุญาตให้ใช้งานขั้นตอนที่ 3 และ 4',
    editHeader: 'ต้องการเปลี่ยนข้อมูล ?',
    addHeader: 'ต้องการเพิ่มวิชาเรียนเวลาเดียวกัน ?',
    addContent: 'ต้องตั้งค่าผู้สอนในขั้นตอนที่ 3 อีกครั้ง'
}
