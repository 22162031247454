export default{
    secName:'ห้อง',
    courseCode:'รหัสวิชา',
    courseName:'ชื่อวิชา',
    totalStd:'จำนวนนักเรียน',
    courseType:'ประเภท',
    updatedDate:'อัปเดตล่าสุด',
    courseTypes: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    },
    search:'ค้นหา',
    searchPlaceHolder:'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    allType:'ประเภททั้งหมด',
    summer:'ซัมเมอร์'
}