export default {
    header: 'ตารางสอน',
    addBtn: 'เพิ่มหมายเหตุ',
    table: {
        head: 'คาบเรียน'
    },
    deleteModal: {
        header: 'ต้องการลบหมายเหตุ ?'
    },
    remarks: [
        'รายวิชาที่ต้องสอน',
        'วิชาเลือก (ขึ้นอยู่กับการลงทะเบียนวิชาเลือก)',
        'อื่นๆ'
    ]
}