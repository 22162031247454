export default {
    courseList:'รายวิชา',
    courseId:'รหัสวิชา',
    name:'ชื่อวิชา',
    type:'ประเภท',
    level:'ระดับชั้น',
    firstTitle: 'ข้อมูลวิชาจากหลักสูตร',
    secondTitle:'ชื่อวิชาที่แสดงบนใบเกรด',
    studentCount:'นักเรียนที่ลงทะเบียน',
    add:'เพิ่ม',
    titleDetail:'คำแนะนำ',
    firstContext:'ระบุชื่อวิชาที่แสดงบนใบเกรดของแต่ละกลุ่ม (ถ้ามี)',
    secondaryContext:'เลือกห้องเรียนและรายชื่อนักเรียนที่ต้องการลงทะเบียนกลุ่มนั้น',
    thirdContext:'เลือกครูผู้สอน ที่ต้องสอนนักเรียนกลุ่มนั้นแล้วกด “ยืนยัน”',
    summer:"Summer",
    courseType: {
        BASIC_COURSE: 'Required subject',
        ADDITIONAL_COURSE: 'Selective subject',
        COUNSELLING_ACTIVITY: 'Counselling activities',
        STUDENT_ACTIVITY: 'Student activities',
        ACTIVITY_FOR_SOCIAL: 'Activities for social and public interst'
    },
    index:"สารบัญ",
    deleteModal:'ต้องการลบสารบัญ ?',
    contentModel:' รายชื่อนักเรียนจะถูกล้าง และต้องตั้งค่าใหม่ทั้งหมด',
    person:'คน'
    
}