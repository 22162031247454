export default {
    settingTemplate: 'ตั้งค่าตารางเรียน',
    totalPeriodIncludeLunch: 'จำนวนคาบเรียนรวมพักกลางวัน',
    lunchCheckBox: 'พักกลางวันเวลาเดียวกัน',
    day: 'วัน',
    periods: 'จำนวนคาบ',
    lunchPeriod: 'คาบที่พักกลางวัน',
    lunchTime: 'พักกลางวัน (นาที)',
    endTime: 'เลิกเรียน',
    sun: 'อาทิตย์',
    mon: 'จันทร์',
    tue: 'อังคาร',
    wen: 'พุธ',
    thu: 'พฤหัสบดี',
    fri: 'ศุกร์',
    sat: 'เสาร์',
    closeToggle: 'ปิด',
    period: 'คาบ',
    periodOrder: 'คาบที่',
    placeHolderTimeSelect: '- เลือกเวลา -',
    totalPeriods: 'จำนวนคาบเรียนทั้งหมด',
    periodPerWeek: 'คาบ / สัปดาห์',
    requireField: 'ต้องระบุ',
    duplicateName: 'ไม่สามารถใช้ชื่อซ้ำ',
    endTimeLabel: '{hour} : {min} น.'
}
