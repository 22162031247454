export default {
    departmentName: 'กลุ่มสาระการเรียนรู้',
    totalSubject: 'จำนวนวิชา',
    departmentType: 'ประเภท',
    latestUpdate: 'อัปเดตล่าสุด',
    createdBy: 'สร้างโดย',
    search: 'กลุ่มสาระ',
    addBtn: 'กลุ่มสาระ',
    textHeader: 'กลุ่มสาระการเรียนรู้',
    emptyTable: 'กรุณาเพิ่มกลุ่มสาระ',
    searchPlaceHolder: 'ค้นหาโดยชื่อวิชากลุ่มสาระ',
    subject: 'รายวิชา',
    activity: 'กิจกรรมพัฒนาผู้เรียน'
}
