export default {
    header: 'เวลาเรียนแต่ละห้อง',
    treeSelect: {
        sessionTotal: 'คาบ',
        placeholder: '- เลือกห้องเรียน -'
    },
    contentTitle: 'กำหนดเวลาเรียน',
    timeTemplate: {
        label: 'เวลาเรียนค่าตั้งต้น',
        placeholder: '- เลือกเวลาเรียนค่าตั้งต้น -'
    },
    totalPeriod: {
        prepend: 'จำนวนคาบเรียนทั้งหมด',
        append: 'คาบ / สัปดาห์'
    },
    classrooms: {
        label: 'ระดับชั้น / ห้องเรียน',
        placeholder: '- เลือกห้องเรียน -'
    },
    btn: {
        addClassrooms: 'เพิ่มห้องเรียน',
        confirm: 'ยืนยัน'
    },
    table: {
        noData: 'เลือกเวลาค่าตั้งต้นตารางเรียนและเลือกห้องเรียนที่จะใช้ตารางนั้น',
        totalRooms: {
            prepend: 'จำนวนห้องทั้งหมด',
            append: 'ห้อง'
        },
        headers: {
            studentYear: 'ระดับชั้น',
            room: 'ห้อง',
            programName: 'แผนการเรียน',
            totalCourse: 'จำนวนวิชา',
            totalPeriod: 'คาบ / สัปดาห์'
        }
    },
    modal: {
        header: 'ต้องการเปลี่ยนเวลาเรียนค่าตั้งต้น?',
        content: [
            'รายการห้องเรียน จะถูกล้างออกทั้งหมด',
            'คุณต้องเพิ่มรายการห้องเรียนใหม่อีกครั้ง'
        ]
    },
    notify: {
        updateTemplateClassList: 'อัพเดตเวลาเรียนแต่ละห้อง',
        deleteIndexList: 'ลบสารบัญ'
    }
}
