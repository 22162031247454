export default {
    courseText: 'วิชาเรียน',
    course: 'วิชา',
    basic: 'พื้นฐาน',
    studentActivity: 'กิจกรรมนักเรียน',
    additional: 'เพิ่มเติม',
    studentYear: 'ระดับชั้น',
    totalClassroom: 'จำนวนห้องเรียน',
    totalPeriodForAssigning: 'คาบเรียนที่ต้องกำหนด',
    firstRowInfo: 'วิชาที่นักเรียนสามารถเลือกเรียนได้เอง เช่น ชุมนุม / ชมรม / วิชาเสรี',
    secondRowInfo: 'ระบบจะให้ระบุเฉพาะชื่อครูผู้สอนที่รับผิดชอบในรายวิชานั้น',
    thirdRowInfo: 'เมื่อเปิดภาคเรียน ครูผู้สอนที่ระบุไว้จะสามารเลือกรายชื่อนักเรียนที่เรียนวิชานั้นเองได้',
    classroom: 'ห้อง',
    totalPeriod: 'จำนวนคาบ',
    instructor: 'เลือกครูผู้สอน',
    simultaneous: 'วิชาเรียนเวลาเดียวกัน',
    timetable: 'ตารางเรียน',
    teacherNotAvailable: 'ครูผู้สอนไม่ว่าง ณ คาบที่เลือก',
    addInstructor: 'เพิ่มครูผู้สอน',
    selectCourse: '- เลือกวิชา -',
    notShowCourse: 'วิชานี้จะไม่แสดงบนตารางเรียน กรุณาระบุครูผู้สอนสำหรับการบันทึกคะแนน',
    notAvailableAt: 'ตารางเรียน : ',
    errorHeader: 'ตรวจพบข้อผิดพลาด',
    errorBody: 'ครูผู้สอนไม่ว่าง ณ คาบที่กำหนด',
    emptyState: 'เลือกวิชาเพื่อกำหนดครูผู้สอน',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    period: 'คาบ',
    first_section: 'คาบแฝงของผู้สอน ไม่ต้องระบุในขั้นตอนนี',
    second_section: 'วิชาที่นักเรียนสามารถเลือกเรียนได้เอง เช่น ชุมนุม / ชมรม / วิชาเสรี จะไม่สามารถระบุรายชื่อผู้สอนได้ ณ ขั้นตอนนี',
    third_section: 'เมื่อเปิดภาคเรียน ครูวิชาการสามารถเลือกรายชื่อผู้สอน และนักเรียนเพื่อลงทะเบียน ณ เมนู ลงทะเบียนวิชาเลือก',
    somethingWentWrong: 'เกิดข้อผิดพลาด',
    pleaseCheckData: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
}
