<template>
    <v-app :class="scssLocale">
        <loading-component v-show="this.$store.state.loading" />
        <div v-if="!isLineLiff">
            <router-view />
        </div>
        <div v-else>
            <div v-if="errorLineLiff === ''" class="content-section-line">
                <navbar-line-liff />
                <div class="content-container-line">
                    <v-main>
                        <router-view />
                    </v-main>
                    <my-notification />
                </div>
            </div>
            <div v-else>
                <card-error-line-liff>
                    <template #modal-header>
                        <div v-if="errorLineLiff === 'notInPeriod'">
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.notPeriod.title") }}
                            </div>
                        </div>
                        <div v-else-if="errorLineLiff === 'noTeaching'">
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.noTeaching.title") }}
                            </div>
                        </div>
                    </template>
                    <template #modal-content>
                        <div v-if="errorLineLiff === 'notInPeriod'">
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.notPeriod.content1")}}
                            </div>
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.notPeriod.content2")}}
                            </div>
                            <div class="mt-4">
                                <img
                                    src="@/assets/image/absentRecordLineLiff/emptyTable.png"
                                    alt=""
                                    width="191"
                                    height="131"/>
                            </div>
                        </div>
                        <div v-else-if="errorLineLiff === 'noTeaching'">
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.noTeaching.content1") }}
                            </div>
                            <div>
                                {{ $vuetify.lang.t("$vuetify.studentAttendanceManagementLiffPage.noTeaching.content2") }}
                            </div>
                            <div class="mt-4">
                                <img
                                    src="@/assets/image/absentRecordLineLiff/noTeaching.png"
                                    alt=""
                                    width="191"
                                    height="131"/>
                            </div>
                        </div>
                    </template>
                </card-error-line-liff>
            </div>
        </div>
    </v-app>
</template>

<script>
import MyNotification from '@/components/MyNotification.vue'
import LoadingComponent from './components/LoadingPage.vue'
import NavbarLineLiff from '@/components/NavbarLineLiff.vue'
import CardErrorLineLiff from '@/components/CardErrorLineLiff.vue'
// import VConsole from 'vconsole'

export default {
    name: 'App',

    components: {
        LoadingComponent,
        MyNotification,
        NavbarLineLiff,
        CardErrorLineLiff
    },

    async mounted() {
        // const vConsole = new VConsole()
        // this.$liffUtil.setAccessToken('K7rleNU7KMvXESSarNSgwQ==')
        // this.$liffUtil.setLineUserId('U7b7e0571f3e7d979839b99e036d0ac8f')
    },

    computed: {
        isLineLiff() {
            return this.$route.fullPath.includes('/line-liff')
        },

        scssLocale() {
            // switch to which scss by current language selected (global.scss)
            return this.$vuetify.lang.current + '-locale'
        },

        errorLineLiff() {
            return this.$store.state.lineLiff.isError
        }
    }
}
</script>

<style lang="scss">
.content-section {
    background-color: $white;
    position: absolute;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    width: calc(100% - 257px);
    right: 0;
    min-height: fit-content;
}
.content-section-line {
    background-color: $lapis-lazuli;
    height: 100vh;
}
.content-container-line {
    height: calc(100vh - 80px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 16px;
    background-color: $cultured;
}
.content-container {
    min-height: calc(100vh - 68px);
}

.theme--light.v-application {
    background: $ghost-white;
}
</style>
