export default{
    title:'วิชาที่ไม่แสดงบนตารางสอน',
    room:'ห้อง',
    courseName:'ชื่อวิชา',
    stdCount:'จำนวนนักเรียน',
    type:'ประเภท',
    courseType: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    },
}