import store from '@/store'
import axios from '@/axios'
import oneUserAccessModule from '@/constants/oneUserAccessModule'
import warningSaveModule from '@/constants/warningSaveModule'
export default {
    methods: {
        async setTimeoutPage(route) {
            // this.$store.commit('reSetChangedDataList')
            // this.$store.commit('reSetHasChanged')
            const modules = oneUserAccessModule.modules
            const moduleInfo = modules.find(o => o.name === route.name)
            const key = moduleInfo.primKey
            const primKey = route.params[key]
            const moduleName = moduleInfo.name
            let secKey = null
            const userAccessedToken = localStorage.getItem('userAccessedToken')
            if (moduleInfo.secKey) {
                secKey = route.params[moduleInfo.secKey]
            }
            let localStorageKeyOfModule = moduleName.concat('P', primKey)
            if (secKey !== null) {
                localStorageKeyOfModule = localStorageKeyOfModule.concat('S', secKey)
            }
            let benchmarkSessionKey = localStorage.getItem('sessionKey')
            const localSessionKeyOfModule = localStorageKeyOfModule.concat('SK', benchmarkSessionKey)
            // remove latest active time of deleted module 
            localStorage.removeItem(localStorageKeyOfModule)
            // remove session key of deleted module 
            localStorage.removeItem(localSessionKeyOfModule)
            let url = `v2/delete/user/access/moduleName/${moduleName}/primKey/${primKey}`
            if (secKey !== null) {
                url += `?secKey=${secKey}`
            }
            // if (accessedToken !== null && accessedToken) {
            //     if (secKey !== null) {
            //         url += `&accessToken=${accessedToken}`
            //     } else {
            //         url += `&accessToken=${accessedToken}`
            //     }
            // }
            const res = await this.axios.post(url, {}, {
                headers: {
                    xAccessToken: `${userAccessedToken}`
                }
            })
            if (store.state.warning.access) {
                store.dispatch('openAccessModal', 'access')
            } else if (store.state.warning.timeout) {
                store.dispatch('openAccessModal', 'timeout')
            }

        },

        confirmSave(event) {
            event.returnValue = 'You have some unsaved changes'
            return 'You have some unsaved changes'
        },

        startUpdateUserAccessTimeInterval() {
            this.stopUpdateUserAccessTimeInterval()
            const self = this
            window.updateUserAccessTimeInterval = setInterval(() => {
                if (!self) {
                    clearInterval(window.updateUserAccessTimeInterval)
                }

                self.currentTime = new Date()
            }, 1500000)
        },

        stopUpdateUserAccessTimeInterval() {
            if (window.updateUserAccessTimeInterval) {
                clearInterval(window.updateUserAccessTimeInterval)
            }
        },

        // startUpdateActiveTimeInterval () {
        //     this.stopUpdateUserAccessTimeInterval()
        //     const self = this
        //     window.updateUserActiveTimeInterval = setInterval(() => {
        //         if (!self) {
        //             clearInterval(window.updateUserActiveTimeInterval)
        //         } 

        //         this.updateLocalActiveTimeStorage(this.$route)
        //     }, 300000)
        //     const modules = oneUserAccessModule.modules
        //     const moduleInfo = modules.find( o => o.name === this.$route.name)
        //     const key = moduleInfo.primKey
        //     const primKey = this.$route.params[key]
        //     const moduleName = moduleInfo.name
        //     let localStorageKeyOfModule = moduleName.concat('P', primKey)
        // },

        stopUpdateActiveTimeInterval() {
            if (window.updateUserActiveTimeInterval) {
                clearInterval(window.updateUserActiveTimeInterval)
            }
        },

        async updateUserAccessTime(route) {
            const accessToken = localStorage.getItem('userAccessedToken')
            const modules = oneUserAccessModule.modules
            const moduleInfo = modules.find(o => o.name === route.name)
            const key = moduleInfo.primKey
            const primKey = route.params[key]
            const moduleName = moduleInfo.name
            let url = `v2/update/active/user/access/moduleName/${moduleName}/primKey/${primKey}`
            let secKey = null
            if (moduleInfo.secKey) {
                secKey = route.params[moduleInfo.secKey]
                url += `?secKey=${secKey}`
            }
            const res = await this.axios.post(url, {}, {
                headers: {
                    xAccessToken: `${accessToken}`
                }
            })


        },

        updateLocalActiveTimeStorage(route) {
            // for update localStorage 
            const modules = oneUserAccessModule.modules
            const moduleInfo = modules.find(o => o.name === route.name)
            if (moduleInfo) {
                const key = moduleInfo.primKey
                const primKey = route.params[key]
                const moduleName = moduleInfo.name
                let localStorageKeyOfModule = moduleName.concat('P', primKey)
                let secKey = null
                if (moduleInfo.secKey) {
                    secKey = route.params[moduleInfo.secKey]
                }
                if (secKey !== null) {
                    localStorageKeyOfModule = localStorageKeyOfModule.concat('S', secKey)
                }
                localStorage.removeItem(localStorageKeyOfModule)
                // use present time to be latest active of this module 
                localStorage.setItem(localStorageKeyOfModule, new Date())
            }
        }
    },

    beforeRouteLeave: function (to, from, next) {
        const modules = oneUserAccessModule.modules
        const moduleInfo = modules.find(o => o.name === from.name)

        if (moduleInfo) {
            // this.$store.commit('setIsPathChange')
            // const checkIsSaved = warningSaveModule.modules.find(o => o.name === this.$route.name)
            const hasChange = this.$store.state.hasChange
            // if (checkIsSaved && hasChange) {
            //     this.$store.commit('openConfirmSave')
            //     this.$store.commit('setRoute', to)
            // } else {
            //     this.setTimeoutPage(from)
            //     next()
            // }

            if (hasChange) {
                this.$store.commit('openConfirmLeaveModal')
                this.$store.commit('setRoute', to)
            }else {
                this.setTimeoutPage(from)
                next()
            }

        } else {
            next();
        }

    },
    // beforeunload
    mounted() {
        const modules = oneUserAccessModule.modules
        if (this.$route) {
            const moduleInfo = modules.find(o => o.name === this.$route.name)
            if (moduleInfo) {
                const checkIsSaved = warningSaveModule.modules.find(o => o.name === this.$route.name)
                const hasChange = this.$store.state.hasChange
                if (checkIsSaved && hasChange) {
                    // var myEvent = window.attachEvent || window.addEventListener;
                    // var checkEvent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compitable

                    // myEvent(checkEvent, function (e) { // For >=IE7, Chrome, Firefox
                    //     var confirmationMessage = 'Are you sure to leave the page?';  // a space
                    //     (e || window.event).returnValue = confirmationMessage;
                    //     return confirmationMessage;
                    // });
                }
            }
        }

    }
}