export default{
    title:'บันทึกการขาดเรียน',
    date:'วันที่',
    totalPeriod:'จำนวนคาบเรียน',
    totalHour:'จำนวนชั่วโมง',
    cause:{
        title:'สาเหตุ',
        SICK_LEAVE :'ลาป่วย',
        PERSONAL_LEAVE:'ลากิจ',
        SKIP:'หนี' 
    },
    description:'หมายเหตุ',
    linkAdd:'เพิ่มวันขาดเรียน',
    period:'จำนวนคาบ',
    platform:'บันทึกผ่าน',
    save:'บันทึก',
    cancel:'ยกเลิก',
    hour:'ชม.',
    labelDate:'วว / ดด / ปป',
    labelSelect:'- เลือก -',
    labelNote:'ระบุหมายเหตุ (ถ้ามี)',
    success:'บันทึกสำเร็จ'
}