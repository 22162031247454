export default {
    headerTextCreate: 'เพิ่มกลุ่มสาระการเรียนรู้',
    headerTextEdit: 'แก้ไขกลุ่มสาระการเรียนรู้',
    departmentType: 'ประเภทกลุ่มสาระ',
    subject: 'รายวิชา',
    developmentActivity: 'กิจกรรมพัฒนาผู้เรียน',
    nameTh: 'ชื่อกลุ่มสาระ (ไทย)',
    nameEn: 'ชื่อกลุ่มสาระ (อังกฤษ)',
    namePlaceholder: 'ระบุชื่อกลุ่มสาระ',
    save: 'บันทึก',
    cancel: 'ยกเลิก',
    required: 'จำเป็นต้องกรอก',
    duplicate: 'ชื่อซ้ำ'
}
