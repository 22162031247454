export default {
    code: 'รหัสวิชา',
    name: 'ชื่อวิชา',
    type: 'ประเภท',
    year: 'ระดับชั้น',
    semester: 'ภาคเรียน',
    totalHour: 'ชม./ปี',
    credit: 'หน่วยกิต',
    createdDate: 'วันที่สร้าง',
    typePlaceholder: 'ประเภททั้งหมด',
    yearPlaceholder: 'ระดับชั้นทั้งหมด',
    semesterPlaceholder: 'ภาคเรียนทั้งหมด',
    emptyTable: 'กรุณาเพิ่มวิชา',
    toolTips: '40 ชั่วโมง/ปี = 1 หน่วยกิต 1 ชั่วโมง/สัปดาห์ = 0.5 หน่วยกิต',
    highSchool: 'มัธยมศึกษาตอนปลาย',
    middleSchool: 'มัธยมศึกษาตอนต้น',
    primarySchool: 'ประถมศึกษา',
    search: 'ค้นหา',
    course: 'วิชา',
    searchPlaceHolder: 'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    headerTextDelete: 'ต้องการลบกลุ่มวิชา ?',
    draftSave: 'บันทึกฉบับร่าง',
    save: 'บันทึก',
    header: 'วิชาเรียน',
    courseType: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    }
}
