export default {
    placeHold: 'ค้นหาโดย ปีการศึกษา',
    search: 'ค้นหา',
    semester: 'ภาคเรียน',
    draft: 'ฉบับร่าง',
    active: 'กำลังใช้งาน',
    complete: 'สำเร็จ',
    inActive: 'ใช้งานแล้ว',
    firstContentEmpty: 'กรุณาเพิ่มภาคเรียน',
    secondContentEmpty: 'เพื่อเริ่มจัดตารางเรียน / ตารางสอน',
    year: 'ปีการศึกษา',
    contentModal: 'ตารางเรียน/สอน จะถูกล้างข้อมูลทั้งหมด',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    status: 'สถานะ',
    updatedDate: 'อัพเดตล่าสุด',
    createdBy: 'สร้างโดย',
    headerDelete: 'ต้องการลบตารางเรียน/สอน',
    notify: {
        title: 'ลบจัดตาราง',
        success: 'สำเร็จ'
    }
}
