export default {
    title: 'ข้อมูลทั่วไป',
    suffix: {
        totalWeek: 'สัปดาห์'
    },
    edit: 'แก้ไข',
    subTitle: {
        totalWeek: 'จำนวนสัปดาห์การสอน',
        academicYear: 'ปีการศึกษา',
        semester: 'ภาคเรียน',
        semesterOpen: 'วันเปิดภาคเรียน',
        department: 'กลุ่มสาระการเรียนรู้',
        timetable: 'จำนวนตารางเรียนที่ต้องจัด',
        defineClassroom: 'จำนวนห้องที่ต้องกำหนด',
        electiveCourse: 'วิชาเลือก',
        course: 'จำนวนวิชาทั้งหมด',
        classroom: 'จำนวนห้องที่ต้องจัด',
        instructor: 'จำนวนครูผู้สอน'
    },
    note: [
        'กำหนดเงื่อนไขครู ณ ภาคเรียนนั้น (ถ้ามี) กรุณากำหนดก่อนทำขั้นตอนด้านล่าง',
        'ระบุจำนวนสัปดาห์การสอน และ ทำตามขั้นตอนจาก 1 - 4 ก่อนกด เริ่มใช้งานหลังจากนั้นจะไม่สามารถแก้ไขข้อมูลได้',
        'เมื่อถึงวันเปิดเทอมแล้วจะไม่สามารถจัดตารางเรียนในภาคเรียนนั้นได้',
        'หมายเหตุ: ห้องเรียน ที่มีวิชาที่เรียนเวลาเดียวกัน ต้องใช้เวลาเรียนค่าตั้งต้นเดียวกันเท่านั้น'
    ],
    noteStep2: [
        ' กรุณากำหนดห้องเรียนที่เรียนวิชาเลือก',
        ' ให้ครบทุกห้องเรียน โปรดตรวจสอบ',
        '- วิชา : ประเภทวิชาเลือก',
        '- เวลาเรียนค่าตั้งต้นทั้งหมดของวิชานั้น',
        '- ห้องเรียนทั้งหมดที่ต้องเรียนวิชานั้น' 
    ],
    noteStep3:' กรุณาระบุครูผู้สอนให้ครบทุกกลุ่มสาระเพื่อยืนยันเริ่มใช้งาน',
    btnTitle: [
        'สำเร็จ',
        'พิมพ์ตารางเรียนทั้งหมด (Excel)',
        'พิมพ์ตารางสอนทั้งหมด (PDF)',
        'ยืนยันการแก้ไข'
    ],
    modal: {
        title: {
            timetable: 'ต้องการเริ่มใช้งานตารางเรียน / สอน ?',
            timetableTemplate: 'ยืนยันเริ่มใช้งานเวลาเรียนตั้งต้น ?',
            inclusiveCourse: 'ยืนยันเริ่มใช้งานวิชาเรียนรวม ?',
            instructor: 'ยืนยันเริ่มใช้งานครูผู้สอน ?',
            confirmEdit: 'ต้องการแก้ไขข้อมูล?',
            warningEditStep: 'เกิดข้อผิดพลาด'
        },
        content: {
            confirmEdit: [
                'การเปลี่ยนแปลงข้อมูล ส่งผลกระทบไปยังขั้นตอนที่ 3 ที่ต้องไปตั้งค่าใหม่อีกครั้ง',
                'การเปลี่ยนแปลงข้อมูล ส่งผลกระทบไปยังขั้นตอนที่ 4 ที่ต้องไปตั้งค่าใหม่อีกครั้ง',
                'ผู้ใช้งาน ที่อยู่ ณ ขั้นตอนอื่น จะไม่สามารถแก้ไขข้อมูลได้ จนกว่าคุณจะแก้ไขข้อมูลสำเร็จ'
            ],
            warningEditStep: [
                'ไม่สามารถแก้ไขข้อมูลได้ เนื่องจากมีผู้ใช้งาน',
                'กำลังแก้ไขข้อมูลในขั้นตอนที่'
            ],
            warningConfirm: [
                'หลังจากยืนยันจะไม่สามารถแก้ไขข้อมูลได้',
                'หลังจากยืนยันจะไม่สามารถแก้ไขรายชื่อครูได้',
                'ยกเว้นรายวิชาที่ระบุครู Dummy สามารถกลับมาแก้ไขได้',
                'เนื่องจากระบบจะนำข้อมูลไปจัดตารางเรียน'
            ]
        }
    }
}
