export default {
    instructor: 'ครูผู้สอน',
    assignInstructorByCourse: 'กำหนดครูผู้สอนตามรายวิชา',
    searchByDepartment: 'ค้นหาโดย กลุ่มสาระการเรียนรู้',
    search: 'ค้นหา',
    department: 'กลุ่มสาระ',
    totalCourse: 'จำนวนวิชา',
    totalInstructor: 'จำนวนครูผู้สอน',
    rowPerPage: 'แถวต่อหน้า',
    from: 'จาก',
    academicYear: 'ปีการศึกษา',
    timetableSetting: 'จัดตารางเรียน'
}
