export default{
    secName:'ห้อง',
    courseCode:'รหัสวิชา',
    courseName:'ชื่อวิชา',
    totalStd:'จำนวนนักเรียน',
    courseType:'ประเภท',
    updatedDate:'อัปเดตล่าสุด',
    courseTypes: {
        BASIC_COURSE: 'Required subject',
        ADDITIONAL_COURSE: 'Selective subject',
        COUNSELLING_ACTIVITY: 'Counselling activities',
        STUDENT_ACTIVITY: 'Student activities',
        ACTIVITY_FOR_SOCIAL: 'Activities for social and public interst'
    },
    search:'ค้นหา',
    searchPlaceHolder:'ค้นหาโดย รหัสวิชา / ชื่อวิชา',
    allType:'ประเภททั้งหมด',
    summer:'Summer'
}