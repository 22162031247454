export default {
    academic: 'Academic',
    manageCurriculum: 'งานพัฒนาหลักสูตร',
    manageDepartmentAndCourse: 'จัดการกลุ่มสาระการเรียนรู้และวิชาเรียน',
    manageCourse: 'จัดการหลักสูตร',
    schedule: 'จัดตารางเรียน/ตารางสอน',
    instructorSchedule: 'ตารางสอน',
    gradeRecordTitle: 'บันทึกผลการเรียน',
    gradeRecordSetting: 'ตั้งค่าการบันทึกผลการเรียน',
    registerElectiveCourses: 'ลงทะเบียนวิชาเลือก',
    activityRecord: 'บันทึกผลกิจกรรม',
    gradeRecord: 'บันทึกผลการเรียน',
    academicSummary: 'สรุปผลการเรียน',
    educationalDocuments: 'เอกสารทางการศึกษา',
    transcript: 'ระเบียนแสดงผลการเรียน(ปพ.1)',
    identificationReport: 'แบบรายงานประจำตัวนักเรียน',
    instructorAbsentRecord: 'บันทึกเวลาขาดเรียน'
}
