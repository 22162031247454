export default{
    title:'วิชาที่ไม่แสดงบนตารางสอน',
    room:'ห้อง',
    courseName:'ชื่อวิชา',
    stdCount:'จำนวนนักเรียน',
    type:'ประเภท',
    courseType: {
        BASIC_COURSE: 'Required subject',
        ADDITIONAL_COURSE: 'Selective subject',
        COUNSELLING_ACTIVITY: 'Counselling activities',
        STUDENT_ACTIVITY: 'Student activities',
        ACTIVITY_FOR_SOCIAL: 'Activities for social and public interst'
    },
}