import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '@/axios'
import oneUserAccessModule from '@/constants/oneUserAccessModule'
import permissionConstant from '@/constants/permissions.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/redirect',
        name: 'Redirect',
        component: () => import('@/views/Redirect'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/',
        redirect: '/errors'
    },
    {
        path: '/errors',
        name: 'ErrorPage',
        component: () => import('@/views/ErrorPage'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/primary',
        name: 'Primary',
        component: () => import('../views/Primary.vue'),
        children: [
            {
                path: '/curriculum/departments',
                name: 'DepartmentList',
                component: () => import('@/views/department/DepartmentList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/departments/:departmentId/info',
                name: 'DepartmentInfo',
                component: () => import('@/views/department/DepartmentInfo'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            // Curriculum
            {
                path: '/curriculum/list',
                name: 'CurriculumList',
                component: () => import('@/views/curriculum/CurriculumList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/list/:curriculumId/programs',
                name: 'CurriculumProgramList',
                component: () => import('@/views/curriculum/CurriculumProgramList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/list/:curriculumId/programs/:programId',
                name: 'CurriculumProgramPage',
                component: () => import('@/views/curriculum/CurriculumProgramPage'),
                meta: {
                    requiresAuth: true,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                    // id: params.programId
                },
                props: { isSaved: false }
            },
            // Timetable
            {
                path: '/curriculum/timetable',
                name: 'TimetableManagement',
                component: () => import('@/views/timetable/TimetableManagement'),
                children: [
                    {
                        path: 'timetable-list',
                        name: 'TimetableList'
                    },
                    {
                        path: 'timetable-templateList',
                        name: 'TimetableTemplateList'
                    },
                ],
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/template/:templateId?',
                name: 'TimeTableTemplate',
                component: () => import('@/views/timetable/TimetableTemplate'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/instructor/departments',
                name: 'InstructorDepartmentList',
                component: () => import('@/views/timetable/InstructorDepartmentList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/instructor/departments/:departmentId',
                name: 'InstructorPage',
                component: () => import('@/views/timetable/InstructorPage'),
                meta: {
                    requiresAuth: true,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER,
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/period',
                name: 'SimultaneousPage',
                component: () => import('@/views/timetable/SimultaneousPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId',
                name: 'TimetablePage',
                component: () => import('@/views/timetable/TimetablePage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/classrooms',
                name: 'ClassroomPage',
                component: () => import('@/views/timetable/ClassroomPage'),
                meta: {
                    requiresAuth: true,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/classroom/:classroomId',
                name: 'TimetableStudentPage',
                component: () => import('@/views/timetable/TimetableStudentPage'),
                meta: {
                    requiresAuth: true,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/schedules/classrooms',
                name: 'TimetableScheduleClassroomList',
                component: () => import('@/views/timetable/TimetableScheduleClassroomList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/schedules/instructors',
                name: 'TimetableScheduleInstructorList',
                component: () => import('@/views/timetable/TimetableScheduleInstructorList'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/schedules/instructors/:teacherId',
                name: 'TimetableScheduleInstructorPage',
                component: () => import('@/views/timetable/TimetableScheduleInstructorPage'),
                meta: {
                    requiresAuth: true,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/curriculum/timetable/:timetableId/tag-conditions',
                name: 'TimetableTagConditionPage',
                component: () => import('@/views/timetable/TimetableTagConditionPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            //instructor
            {
                path: '/instructor/timetable',
                name: 'TeacherScheduleListPage',
                component: () => import('@/views/instructorTimetable/TeacherScheduleListPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/timetable/:timetableId',
                name: 'TeacherScheduleInfoPage',
                component: () => import('@/views/instructorTimetable/TeacherScheduleInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/absent-recording',
                name: 'StudentAttendanceManagementListPage',
                component: () => import('@/views/instructorAbsentRecording/StudentAttendanceManagementListPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/absent-recording/:timetableId',
                name: 'StudentCoursesListPage',
                component: () => import('@/views/instructorAbsentRecording/StudentCoursesListPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/absent-recording/:timetableId/section/:sectionId',
                name: 'StudentAttendanceListByCoursePage',
                component: () => import('@/views/instructorAbsentRecording/StudentAttendanceListByCoursePage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/scoring',
                name: 'TeacherAcademicRecordListPage',
                component: () => import('@/views/instructorScoring/TeacherAcademicRecordListPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/scoring/:timetableId',
                name: 'TeacherCourseListAcademicRecordInfoPage',
                component: () => import('@/views/instructorScoring/TeacherCourseListAcademicRecordInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/scoring/:timetableId/letter-course/:sectionId',
                name: 'TeacherCourseTypeByLetterInfoPage',
                component: () => import('@/views/instructorScoring/TeacherCourseTypeByLetterInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            {
                path: '/instructor/scoring/:timetableId/passable-course/:sectionId',
                name: 'TeacherCourseTypeByPassableInfoPage',
                component: () => import('@/views/instructorScoring/TeacherCourseTypeByPassableInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_INSTRUCTOR
                    ]
                }
            },
            //ElectiveCourses
            {
                path: '/elective-courses/list',
                name: 'ElectiveCoursesPage',
                component: () => import('@/views/electivecourses/ElectiveCoursesPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/elective-courses/list/:timetableId/elective-course-info',
                name: 'ElectiveCourseInfoPage',
                component: () => import('@/views/electivecourses/ElectiveCourseInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/elective-courses/list/:timetableId/course/:courseId',
                name: 'CourseInfoPage',
                component: () => import('@/views/electivecourses/CourseInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/elective-courses/list/:timetableId/course-overview',
                name: 'ElectiveCourseOverviewsPage',
                component: () => import('@/views/electivecourses/ElectiveCourseOverviewsPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            {
                path: '/elective-courses/list/:timetableId/student-info/:studentId',
                name: 'StudentElectiveCourseInfoPage',
                component: () => import('@/views/electivecourses/StudentElectiveCourseInfoPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            },
            //ElectiveCoursesSetting
            {
                path: '/elective-courses/setting',
                name: 'ElectiveCourseSettingPage',
                component: () => import('@/views/electiveCoursesSetting/ElectiveCourseSettingPage'),
                meta: {
                    requiresAuth: false,
                    permissions: [
                        permissionConstant.role.ROLE_ACADEMIC_TEACHER
                    ]
                }
            }
        ]
    },
    //instructor absent record line liff
    {
        path: '/line-liff/absent-recording',
        name: 'StudentAttendanceManagementLiffPage',
        component: () => import('@/views/absentRecordLineLiff/StudentAttendanceManagementLiffPage'),
    },
    {
        path: '/line-liff/absent-recording/sections/:teacherPeriodId',
        name: 'StudentAttendanceListByCourseLineLiffPage',
        component: () => import('@/views/absentRecordLineLiff/StudentAttendanceListByCourseLineLiffPage'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (_to, _from, next) => {
    store.commit('setBreadCrumbs', [])
    const modules = oneUserAccessModule.modules
    if (_to.name !== _from.name) {
        const { requiresAuth, permissions } = _to.meta
        const currentRole = localStorage.getItem('currentRole')
        // validate permissions
        if (permissions && permissions.length > 0 && !permissions.includes(currentRole)) {
            next({ path: '/errors' })
        }

        // validate auth requires
        if (requiresAuth) {
            const moduleInfo = modules.find(o => o.name === _to.name)
            const key = moduleInfo.primKey
            const primKey = _to.params[key]
            const moduleName = moduleInfo.name
            let secKey = null
            if (moduleInfo.secKey) {
                secKey = _to.params[moduleInfo.secKey]
            }
            let url = `v2/check/user/access/moduleName/${moduleName}/primKey/${primKey}`
            const userAccessedToken = localStorage.getItem('userAccessedToken')
            if (secKey !== null) {
                url += `?secKey=${secKey}`
            }
            let localStorageKeyOfModule = moduleName.concat('P', primKey)
            if (secKey !== null) {
                localStorageKeyOfModule = localStorageKeyOfModule.concat('S', secKey)
            }
            // if (accessedToken !== null && accessedToken) {
            //     if (secKey !== null) {
            //         url += `&accessToken=${accessedToken}`
            //     } else {
            //         url += `?accessToken=${accessedToken}`
            //     }
            // }
            store.dispatch('setLoading', true)
            const res = await axios.post(url, {}, {
                headers: { xAccessToken: `${userAccessedToken}` }

            })
            const latestActive = localStorage.getItem(localStorageKeyOfModule)
            const differentTime = Date.now() - Date.parse(latestActive)
            let benchmarkSessionKey = localStorage.getItem('sessionKey')
            let sessionKey = (Math.random() + 1).toString(36).substring(7)
            if (!benchmarkSessionKey) {
                benchmarkSessionKey = sessionKey
            }
            const localSessionKeyOfModule = localStorageKeyOfModule.concat('SK', benchmarkSessionKey)
            const compareSessionKey = sessionStorage.getItem('sessionKey')

            // if this session does't have key sessionKey
            if (!compareSessionKey) {
                sessionStorage.setItem('sessionKey', sessionKey)
            }
            // 360000
            if (latestActive && differentTime < 1 && benchmarkSessionKey && (benchmarkSessionKey !== compareSessionKey)) {
                this.$store.dispatch('setLoading', false)
                store.dispatch('openAccessModal', 'access')
            } else {
                if (res && res.data && res.data.status === 'ok') {
                    store.dispatch('setLoading', false)
                    localStorage.removeItem(localStorageKeyOfModule)
                    if (userAccessedToken !== res.data.msg) {
                        localStorage.setItem('userAccessedToken', res.data.msg)
                        localStorage.setItem(localStorageKeyOfModule, new Date())
                        localStorage.setItem(localStorageKeyOfModule, sessionKey)
                        sessionStorage.setItem('sessionKey', sessionKey)
                        next()
                    } else {
                        localStorage.setItem(localStorageKeyOfModule, new Date())
                        next()
                    }
                } else {
                    store.dispatch('setLoading', false)
                    next()
                    if (res.data.errors?.userInUsed) {
                        store.dispatch('setAccessModal', { type: 'access', userInUsed: res.data.errors?.userInUsed })
                    } else {
                        store.dispatch('openAccessModal', 'access')
                    }
                }
            }
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
