export default {
    delete: 'Delete',
    cancel: 'Cancel',
    apply: 'Apply',
    headerText: 'Are you sure you want to delete',
    headerCourse: 'ต้องการลบวิชา ?',
    firstContentText: 'วิชาในกลุ่มสาระ',
    lastContentText: 'จะถูกลบทั้งหมด',
    error: 'ERROR',
    warningModal: 'ต้องการลบวิชา'
}
