<template>
    <div class="lang-selector">
        <div
            @click="changeLang('th')"
            :class="underscore('th')">
            ไทย
        </div>
        |
        <div
            @click="changeLang('en')"
            :class="underscore('en')">
            EN
        </div>
    </div>
</template>

<script>
export default {
    name: 'LangSelector',

    methods: {
        changeLang (lang) {
            this.$vuetify.lang.current = lang
            localStorage.setItem('lang', lang)
        },

        underscore (lang) {
            if (this.$vuetify.lang.current === lang) {
                return 'underscore'
            }

            return null
        }
    }
}
</script>

<style lang="scss" scoped>
    .lang-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #131410;
        font-family: Prompt;

        div {
            cursor: pointer;
            padding: 0px 10px;
        }
    }

    .underscore {
        text-decoration: underline;
        pointer-events: none;
    }
</style>
