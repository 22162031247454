export default {
    reportHeader: 'ภาระงานสอนของครูและบุคคลกรทางการศึกษา',
    instructorTitle: 'ผู้สอน',
    instructorCode: 'รหัส',
    academicYear: 'ปีการศึกษา',
    academicSemester: 'ภาคเรียนที่',
    remark: 'สอน ............................ คาบ',
    teachCourse: 'สอนวิชา',
    noData: 'ไม่พบข้อมูล',
    studentYear: 'ระดับชั้น',
    room: 'ห้อง',
}
