export default{
    teacherInfo:'รายละเอียดการสอน และครูผู้สอน',
    time:'เวลาเรียน',
    teacher:"ผู้สอน",
    courseInfo:'ข้อมูลวิชา',
    code:'รหัสวิชา',
    name:'ชื่อวิชา',
    type:'ประเภท',
    totalStd:'จำนวนนักเรียน',
    courseType: {
        BASIC_COURSE: 'พื้นฐาน',
        ADDITIONAL_COURSE: 'เพิ่มเติม',
        COUNSELLING_ACTIVITY: 'กิจกรรมแนะแนว',
        STUDENT_ACTIVITY: 'กิจกรรมนักเรียน',
        ACTIVITY_FOR_SOCIAL: 'กิจกรรมเพื่อสังคมและสาธารณประโยชน์'
    },
    summer:'ซัมเมอร์',
    sameTimeError:{
        header:'ผู้ใช้งานอื่นกำลังแก้ไขข้อมูล',
        context1:'กำลังใช้งานหน้านี้ คุณไม่สามารถ',
        context2:'แก้ไขข้อมูลได้ จนกว่าผู้ใช้งานอื่นจะใช้งานสำเร็จ',
    },
}