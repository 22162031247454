export default {
    timeSetting: 'ตั้งค่าเวลา',
    templateName: 'ชื่อตารางเวลา',
    firstPeriod: 'เรียนคาบที่ 1',
    periodTime: 'เวลาเรียนต่อคาบ (นาที)',
    breakTime: 'พักระหว่างคาบ (ถ้ามี)',
    breakAfter: 'พักช่วงเวลา (ถ้ามี)',
    placeHolderTemplateName: 'เช่น ห้องเรียนปกติ / ห้องเรียนพิเศษ',
    placeHolderTimeSelect: '- เลือกเวลา -',
    placeHolderTotalPeriod: '- เลือกจำนวน -',
    placeHolderLunchBreak: '- คาบที่พัก -',
    placeHolderLunchTime: '- เลือกเวลา -',
    placeHolderFirstPeriod: '_ _:_ _',
    addBreak: 'เพิ่มช่วงพัก',
    required: 'กรุณากรอกข้อมูล',
    afterPeriod: 'หลังคาบที่ ',
    minUnit: 'นาที',
    period: 'คาบ',
    periodOrder: 'คาบที่',
    incorrectTimeFormat: 'Incorrect time format (00:00 - 23:59)'
}
