import axios from 'axios'
import store from './store'
import router from './router'

// const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTY4NDIyODM1NiwiaWF0IjoxNjY4Njc2MzU2LCJzY2hvb2xDb2RlIjoiVDAxIn0.h5ShG15-WHxgNvhA_mDjSXWtDq10Yc03z6Etg01x2xSs_bMvc987gU-5sazJuaQw0IiYGLmqPIFPND6-srItJw'
// const authorization = `Bearer ${token}`

const instance = axios.create({

    baseURL: process.env.VUE_APP_BACKEND,
    // headers: { Authorization: authorization }

})

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('Token')
        config.headers.Authorization = token ? `Bearer ${token}` : token
        const lang = localStorage.getItem('lang')? localStorage.getItem('lang') : 'th'
        config.headers['Accept-Language'] = lang
        if (config.url && process.env.VUE_APP_AZ_CODE) {
            config.url = `${config.url}${config.url.indexOf('?') < 0 ? '?' : '&'}code=${process.env.VUE_APP_AZ_CODE}`
        }
        return config
    },

    error => {
        Promise.reject(error)
    }
)

instance.interceptors.response.use(
    response => {
        if (response && response.data.status === 'error') {
            store.dispatch('setLoading', false)
            // Vue.notify({
            //     title: 'error--header',
            //     text: 'error---text',
            //     type: 'error'
            // })
        }
        return response
    },
    function (error) {
        if (error && error.response && error.response.status === 401) {
            router.push({ name: 'Login' }, () => { })
            const message = error.response.data
            if (!(typeof message === 'string' && message.startsWith('Error:'))) {
                store.dispatch('setErrorModalShow', true)
                store.dispatch('setErrorModalMsg', error.response.data)
                store.dispatch('setLoading', false)
            }

            localStorage.setItem('jwt', null)
            localStorage.setItem('username', null)
        } else if (error && error.response && error.response.status === 500) {
            store.dispatch('setErrorModalShow', true)
            store.dispatch('setErrorModalMsg', error.response.data)
            store.dispatch('setLoading', false)
        } else if (error && error.response && error.response.status === 408) {
            store.dispatch('setLoading', false)
            return error.response
        }
    }
)

export default instance
