import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from '@/lang/en'
import th from '@/lang/th'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { th, en },
        current: 'th'
    }
})
