export default {
    electiveCourseSettingPage:'ตั้งค่าบันทึกผลการเรียน',
    firstTitle:'คำแนะนำ',
    firstContext:'วันและเวลาที่กำหนดจะส่งผลต่อการ',
    secondaryContext:'เปิด-ปิด ระบบลงทะเบียนวิชาเลือก และบันทึกผลการเรียน',
    recordStartDate:'เริ่มบันทึกผล',
    electiveCourseCondition:'กรณีมีวิชาเลือก',
    recordEndDate:'ส่งผลรอบปกติ',
    specialDate:'รอบพิเศษ',
    announceDate : 'ประกาศผล',
    firstCondition1:'วันที่เปิดระบบให้ผู้สอนสามารถบันทึกผลการเรียน',
    firstCondition2:'โดยต้องเป็นวันหลังจากเปิดภาคเรียนเท่านั้น',
    courseConditionDetail:'สามารถลงทะเบียน ได้ตั้งแต่วันเปิดภาคเรียน',
    secondaryCondition:'วันสุดท้ายที่ผู้สอนต้องส่งผลการเรียนรอบปกติ',
    thirdCondition:'ช่วงเวลาการแก้ไขคะแนนรอบพิเศษ',
    fourthCondition:'วันประกาศผลการเรียนผ่านไลน์',

    secondaryTitle:"การประกาศผลการเรียน",
    primaryLevel:'ระดับประถมศึกษา',
    secondaryLevel:'ระดับมัธยมศึกษา',
    primaryDetail:'จะประกาศคะแนนเต็มรายภาคเรียน',
    subPrimaryDetail:'หากเป็นภาคเรียนสุดท้ายในปีนั้น สามารถเปิดฟังก์ชั่นการคำนวณเกรดรายปีได้',
    secondaryDetail:'จะประกาศเกรดรายภาคเรียน',

    placeholder:'ค้นหาโดย ปีการศึกษา',
    search:'ค้นหา',

    year:'ปีการศึกษา',
    semester:'ภาคเรียน',
    status:'สถานะ',
    tooltip:'ระบบจะแสดงรายการให้ตั้งค่าโดยอัตโนมัติ โดยอ้างอิงปีการศึกษาและภาคเรียน ที่จัดตารางเรียน สำเร็จแล้ว',
    summer:'ซัมเมอร์',
    deactivated:"ใช้งานแล้ว",
    active:'กำลังใช้งาน',
    inactive:'ยังไม่เปิดใช้งาน',

    placeholderDate:'วว.ดด.ปป',
    modalTitle:'บันทึกผลการเรียน',
    save:'บันทึก',
    cancel:'ยกเลิก'


}