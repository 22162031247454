export default {
    placeHold: 'ค้นหาโดย ชื่อตารางเวลา',
    search: 'ค้นหา',
    add: 'เวลาเรียน',
    firstTextEmpty: 'กรุณาเพิ่มเวลาเรียนตั้งต้น',
    secondTextEmpty: 'เพื่อใช้เป็นต้นแบบเวลาตารางเรียน',
    firstContentModal: 'ปีการศึกษาที่ยังจัดตารางเรียน',
    secondContentModal: 'ไม่สมบูรณ์ และใช้เวลาค่าตั้งต้นนี้จะถูกล้างข้อมูลทั้งหมด',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    nameTable: 'ชื่อตารางเวลา',
    updateDate: 'อัพเดตล่าสุด',
    createBy: 'สร้างโดย',
    headerDelete: 'ต้องการลบตารางเวลาค่าตั้งต้น',
    notify: {
        title: 'ลบเวลาเรียนค่าตั้งต้น',
        success: 'สำเร็จ'
    }
}
