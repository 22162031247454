export default {
    title:'บันทึกผลการเรียน',
    search:'ค้นหา',
    searchPlaceHolder:'ค้นหาโดย รหัสนักเรียน / ชื่อนักเรียน',
    allComplete:'บันทึกผลผ่านทั้งหมด',
    room:"ห้อง",
    secMember:'เลขที่',
    studentNo:'รหัสนร.',
    fullName:'ชื่อ - นามสกุล',
    grade:'ปกติ',
    specialGrade:'แก้ตัว',
    note:'หมายเหตุ (ถ้ามี)',
    labelNote:'ระบุหมายเหตุ',
    attendPct:'เข้าเรียน',
    updatedAt:'อัปเดตล่าสุด',
    updatedByUsername:'โดย',
    save:'บันทึก',
    cancel:'ยกเลิก',
    pass:'ผ',
    fail:'มผ',
    person:'คน',
    titleModal:'ต้องการบันทึกข้อมูลหรือไม่ ?',
    detailModal:'คุณยังไม่ทำการบันทึกข้อมูล',
    cancelModal:'ปิดโดยไม่บันทึก',
    saveSuccess:'บันทึกสำเร็จ'
}