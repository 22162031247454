export default {
    textTitle: 'จัดตารางเรียน/ตารางสอน',
    titleDetail: 'ขั้นตอนการจัดตารางเรียน/ตารางสอน',
    firstContext: 'เลือกเมนูเวลาเรียนค่าตั้งต้น : กำหนดเวลาเรียนค่าตั้งต้น เพื่อนำไปกำหนดช่วงเวลาเรียนในตารางเรียนแต่ละห้อง',
    secondContext: 'เลือกเมนู จัดตาราง : เพื่อจัดตารางเรียน/ตารางสอน',
    subContext: 'ทำตามขั้นตอน 1 - 4',
    firstSubContext: 'กำหนดเวลาเรียนในแต่ละห้อง โดยเลือกจากเวลาเรียนตั้งต้นที่ตั้งค่าไว้',
    secondSubContext: 'กำหนดรายวิชาที่เรียนรวมกันหลายห้อง',
    thirdSubContext: 'กำหนดครูผู้สอนตามรายวิชา เพื่อกระจายคาบเรียน',
    fourthSubContext: 'สร้างตารางเรียนรายห้อง หลังจากนั้นระบบจะจัดตารางสอนครูโดยอัตโนมัติ หลังจากนั้น กดปุ่ม "สำเร็จ"',
    navBarFirstTitle: 'เวลาเรียนค่าตั้งต้น',
    navBarSecondTitle: 'จัดตาราง'
}
