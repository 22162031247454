export default{
    searchPlaceHolder:'ค้นหาโดย ปีการศึกษา',
    search:'ค้นหา',
    year:'ปีการศึกษา',
    semester:'ภาคเรียน',
    totalCourse:'จำนวนวิชาที่ต้องบันทึก',
    recordEndDate:'ส่งผลรอบปกติ',
    specialDate:'รอบพิเศษ',
    updatedDate:'อัปเดตล่าสุด',
    tooltipYear:'ระบบจะแสดงรายการให้ โดยอัตโนมัติ โดยแสดงประวัติและภาคเรียนปัจจุบัน อ้างอิงจากวันเปิดภาคเรียน',
    notFound:'ไม่พบข้อมูล'
}