const validator = {
    hasValue: (v, message = false) => {
        let result = message
        if (v) {
            if (Array.isArray(v) && v.length > 0) {
                result = true
            } else if ((typeof v === 'object') && (Object.keys(v).length > 0)) {
                result = true
            } else if (v.toString().trim().length > 0) {
                result = true
            }
        }

        return result
    },

    hasSomeCharacter: (v, character, message = false) => {
        let result = message
        if (v) {
            if (!v.includes(character)) {
                result = true
            }
        }

        return result
    },

    lengthNotExceed: (v, max, message = false) => {
        let result = message
        if (v && v.toString().trim().length <= max) {
            result = true
        }

        return result
    },

    lengthExactly: (v, exact, message = false) => {
        let result = message
        if (v && v.toString().trim().length === exact) {
            result = true
        }

        return result
    },

    correctFormat: (v, format, message = false) => {
        let result = message
        if (format.test(v)) {
            result = true
        }

        return result
    },

    correctFormatNotRequired: (v, format, message = false) => {
        let result = message
        if (format.test(v)) {
            result = true
        } else if (v.length === 0) {
            result = true
        }
        return result
    },

    isEqual: (source, target, sourceKey, targetKey, message = false) => {
        let result = message
        let sourceVal = source
        if (sourceKey) {
            if (!source[sourceKey]) {
                return result
            }
            sourceVal = source[sourceKey]
        }

        let targetVal = target
        if (targetKey) {
            targetVal = target[targetKey]
        }

        if (sourceVal.toString() === targetVal.toString()) {
            result = true
        }

        return result
    },

    arrayValueIn: (v, data = [], valueKey = '', dataKey = '', message = false) => {
        if (!v || !data || (data && data.length === 0)) {
            return true
        }

        let result = message
        let arrayValid = true
        if (Array.isArray(v)) {
            for (let j = 0; j < v.length; j++) {
                let itemValid = false
                for (let i = 0; i < data.length; i++) {
                    const isValid = validator.isEqual(v[j], data[i], valueKey, dataKey, message)
                    itemValid = itemValid || (isValid === true)
                }
                arrayValid = arrayValid && itemValid
            }

            if (arrayValid) {
                result = true
            }
        }

        return result
    },

    valueIn: (v, data = [], valueKey = '', dataKey = '', message = false) => {
        if (!v || !data || (data && data.length === 0)) {
            return true
        }

        let result = message
        for (let i = 0; i < data.length; i++) {
            if (validator.isEqual(v, data[i], valueKey, dataKey, message) === true) {
                result = true
                break
            }
        }

        return result
    },

    validatePassword: (v, message = false) => {
        if (!v) {
            return true
        }
        const res = v.match(/\s/)

        if (res && res.length > 0) {
            return message
        }

        return true
    },

    compareMode: () => {
        return {
            GREATER: 1,
            LESS: 2,
            GREATER_EQUAL: 3,
            LESS_EQUAL: 4
        }
    },

    valueCompare: (v, ref, mode, message = false) => {
        if (!v) {
            return true
        }

        const modes = validator.compareMode()
        const modeVals = Object.values(modes)
        if (!modeVals[mode]) {
            return false
        }

        let result = false
        switch (mode) {
            case modes.GREATER:
                result = v > ref
                break

            case modes.LESS:
                result = v < ref
                break

            case modes.GREATER_EQUAL:
                result = v >= ref
                break

            case modes.LESS_EQUAL:
                result = v <= ref
                break

            default:
                break
        }
        return result || message
    },

    isNumeric: (v, message = false) => {
        return /^-?\d+$/.test(v) || message
    },

    correctPhoneNumber: (v, message = false) => {
        try {
            const parts = v.split(' ')
            return ((parts.length === 3) &&
                validator.isNumeric(parts[0]) && (parts[0].length === 2) &&
                validator.isNumeric(parts[1]) && (parts[1].length === 3) &&
                validator.isNumeric(parts[2]) && [3, 4].includes(parts[2].length)) || message
        } catch (error) {
            return false
        }
    },
    isFloat2point: (v, message = false) => {
        return /^[+,-]?\d+(\.\d{1,2})?$/.test(v) || message
    },

}

export default validator
